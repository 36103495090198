import { RoundedCornerSharp } from '@material-ui/icons'
import { rebarData } from './rebarDatabase'
const replaceVlaue = (value) => {
    if (value) {
        if (typeof (value) === 'number')
            return value
        else
            return parseInt("" + value.replace(" psi", "").replace(",", ""))

    } else {
        return 0
    }
}
export const aci25423 = (data) => {
    const [B4, B6, B8, B10, B12, B14, B16, B18, B20, B24] = data;

    const E4 = rebarData[B4]
    const E6 = (replaceVlaue(B6) === 80000 ? 1.15 : (replaceVlaue(B6) === 100000 ? 1.3 : 1))
    const E10 = (B10 === "Yes" ? 0.75 : 1)
    const E14 = (B12 === "No" ? 1 : ((B12 === "Yes" && B14 === "Yes") ? 1.3 : 1))
    const E16 = (B16 === "No" ? 1 : ((B18 < (3 * E4) || B20 < (6 * E4)) ? 1.5 : 1.2))
    const E18 = (E14 * E16 >= 1.7 ? 1.7 : E14 * E16)
    const J24 = (B20 >= E4 ? "Yes" : "No")
    const J25 = (B18 >= E4 ? "Yes" : "No")
    const J26 = (B20 >= (2 * E4) ? "Yes" : "No")
    const K23 = ((B4 === "#3" || B4 === "#4" || B4 === "#5" || B4 === "#6") ? "Yes" : "No")
    const K24 = ((J24 === "Yes" && J25 === "Yes" && B24 === "Yes") ? "Yes" : "No")
    const K25 = ((J26 === "Yes" && J25 === "Yes") ? "Yes" : "No")
    const O24 = ((K23 === "Yes" && K24 === "Yes") ? "1a" : "---")
    const O25 = ((K23 === "Yes" && K25 === "Yes") ? "1b" : "---")
    const O26 = ((K23 === "Yes" && (K24 === "No" && K25 === "No")) ? "2" : "---")
    const R26 = ((K23 === "No" && (K24 === "No" && K25 === "No")) ? "4" : "---")
    const G26 = roundValue(Math.max(((O24 === "1a" || O25 === "1b") ? (replaceVlaue(B6) * E18 * E6 * E4) / (25 * E10 * Math.min(100, Math.sqrt(B8))) : (O26 === "2" ? (3 * replaceVlaue(B6) * E18 * E6 * E4) / (50 * E10 * Math.min(100, Math.sqrt(B8))) : (R26 === "4" ? (3 * replaceVlaue(B6) * E18 * E6 * E4) / (40 * E10 * Math.min(100, Math.sqrt(B8))) : (replaceVlaue(B6) * E18 * E6 * E4) / (20 * E10 * Math.min(100, Math.sqrt(B8)))))), 12), 0)
    const G27 = ((B4 === "#14" || B4 === "#18") ? "N/A" : G26)
    const G28 = ((B4 === "#14" || B4 === "#18") ? "N/A" : roundValue(Math.max(((O24 === "1a" || O25 === "1b") ? (1.3 * replaceVlaue(B6) * E18 * E6 * E4) / (25 * E10 * Math.min(100, Math.sqrt(B8))) : (O26 === "2" ? (1.3 * 3 * replaceVlaue(B6) * E18 * E6 * E4) / (50 * E10 * Math.min(100, Math.sqrt(B8))) : (R26 === "4" ? (1.3 * 3 * replaceVlaue(B6) * E18 * E6 * E4) / (40 * E10 * Math.min(100, Math.sqrt(B8))) : (1.3 * replaceVlaue(B6) * E18 * E6 * E4) / (20 * E10 * Math.min(100, Math.sqrt(B8)))))), 12), 0))
    let response = []

    replaceVlaue(B6) > 60000 && response.push({ name: "Provide at least 0.5 <i style='font-size:18px'>d<sub style='font-size:12px'>b</sub></i> transverse reinforcement where <i>f<sub>y</sub></i> ≥ 80,000 psi and center-to-center bar spacing is less than 6 in. (ACI 25.4.2.2)", value: "", suffix: "", message: "", middle: true })
    const output = [{ name: "db =", value: E4, suffix: "in.", message: "" }, { name: "ψg =", format: 2, value: E6, suffix: "", message: "Factor used to modify development length based on reinforcement grade (ACI Table 25.4.2.5)" }, { name: "λ =", value: E10, suffix: "", message: "Factor used to modify development length based on reduced properties of lightweight concrete (ACI Table 25.4.2.5)", format: 2 }, { name: "ψt =", format: 1, value: E14, suffix: "", message: "Factor used to modify development length based on reinforcement location (ACI Table 25.4.2.5)" }, { name: "ψe =", format: 1, value: E16, suffix: "", message: "Factor used to modify development length based on bar coating (ACI Table 25.4.2.5)" }, { name: "ψtψe =", value: E18, format: 1, suffix: "", message: "Footnote [1] in ACI Table 25.4.2.5" }, { name: "Tension development length for deformed bars 𝓁<sub>d</sub>  =", value: G26, suffix: "in", top: true }, { name: "Class A tension lap splice length 𝓁<sub>st</sub> =", value: G27, suffix: "in", top: true }, { name: "Class B tension lap splice length 𝓁<sub>st</sub> =", value: G28, suffix: "in", top: true }]
    return [...response, ...output];

}

export const generateReportAci25423 = (data) => {
    let array = ["#3", "#4", "#5", "#6", "#7", "#8", "#9", "#10", "#11", "#14", "#18"]
    const report = array.map((item) => {
        data[0] = item
        console.log(data[0])
        return aci25423(data)
    })
    return report;
}

export const generateReportAci25424 = (data) => {
    let array = ["#3", "#4", "#5", "#6", "#7", "#8", "#9", "#10", "#11", "#14", "#18"]
    const report = array.map((item) => {
        data[0] = item
        console.log(data[0])
        return aci25424(data)
    })
    return report;
}
export const aci2543 = (data) => {
    const [B4, B6, B8, B10, B12, B14, B16, B18] = data;

    const E4 = rebarData[B4]
    const E8 = (B8 < 6000 ? (B8 / 15000) + 0.6 : 1)
    const E10 = (B10 === "Yes" ? 0.75 : 1)
    const E12 = ((B4 === "#14" || B4 === "#18") ? 1.6 : (B12 === "Yes" ? 1 : 1.6))
    const E14 = (B14 === "No" ? 1 : 1.2)
    const K15 = (B18 >= 2.5 ? "Yes" : "No")
    const K17 = (B18 >= 6 * E4 ? "Yes" : "No")
    const E18 = ((B4 === "#14" || B4 === "#18") ? 1.25 : (((B16 === "Yes" && K15 === "Yes") || K17 === "Yes") ? 1 : 1.25))
    const part1 = (replaceVlaue(B6) * E14 * E12 * E18 * E8 * Math.pow(E4, 1.5))
    console.log("replaceVlaue(B6) * E14 * E12 * E18 * E8 * (E4 ^ 1.5)", replaceVlaue(B6), E14, E12, E18, E8, Math.pow(E4, 1.5))
    const part2 = (55 * E10 * Math.min(100, Math.sqrt(B8)))
    console.log("part1  :  ", part1)
    console.log("part2  :  ", part2)

    const val1 = (part1 / part2)
    console.log("val1  :  ", val1)
    //const G22 = Math.round(Math.max(((replaceVlaue(B6) * E14 * E12 * E18 * E8 * (E4 ^ 1.5)) / (55 * E10 * Math.min(100, Math.sqrt(B8)))), 8 * E4, 6), 0)
    const G22 = roundValue(Math.max(val1, 8 * E4, 6), 0)

    //console.log("G22 : ", G22)
    let response = []
    replaceVlaue(B6) > 60000 && response.push({ name: "Provide at least 0.5 <i style='font-size:18px'>d<sub style='font-size:12px'>b</sub></i> transverse reinforcement where <i>f<sub>y</sub></i> ≥ 80,000 psi and center-to-center bar spacing is less than 6 in. (ACI 25.4.2.2)", value: "", suffix: "", message: "", middle: true })
    const output = [{ name: "db =", value: E4, suffix: "in.", message: "" }, { name: "ψc =", value: E8, format: 2, suffix: "", message: "Factor used to modify development length based on concrete strength (ACI Table 25.4.3.2)" }, { name: "λ =", format: 2, value: E10, suffix: "", message: "Factor used to modify development length based on reduced properties of lightweight concrete (ACI Table 25.4.3.2)" }, { name: "ψr =", format: 1, value: E12, suffix: "", message: "Factor used to modify development length based on confining reinforcement (ACI Table 25.4.3.2)" }, { name: "ψe =", format: 1, value: E14, suffix: "", message: "Factor used to modify development length based on bar coating (ACI Table 25.4.3.2)" }, { name: "ψo =", format: 2, value: E18, suffix: "", message: "Factor used to modify development length based on bar location (ACI Table 25.4.3.2)" }, { name: "Development length for deformed bars with a standard hook 𝓁<sub>dh</sub> =", value: G22, suffix: "in.", top: true }]

    return [...response, ...output];
}
export const aci2544 = (data) => {
    const [B4, B6, B8, B10, B12, B14, B16, B18] = data;

    const E4 = rebarData[B4]
    const E8 = (B8 < 6000 ? (B8 / 15000) + 0.6 : 1)
    const E10 = (B10 === "Yes" ? 0.75 : 1)
    const E12 = ((B4 === "#14" || B4 === "#18") ? 1.6 : (B12 === "Yes" ? 1 : 1.6))
    const E14 = (B14 === "No" ? 1 : 1.2)
    const K15 = (B18 >= 2.5 ? "Yes" : "No")
    const K17 = (B18 >= 6 * E4 ? "Yes" : "No")
    const E18 = ((B4 === "#14" || B4 === "#18") ? 1.25 : (((B16 === "Yes" && K15 === "Yes") || K17 === "Yes") ? 1 : 1.25))
    // Math.round(Math.max(((replaceVlaue(B6) * E14 * E12 * E18 * E8 * (E4 ^ 1.5)) / (75 * E10 * Math.min(100, SQRT(B8)))), 8 * E4, 6), 0)
    console.log("8 * E4 : ", 8 * E4)
    console.log("first  value : ", ((replaceVlaue(B6) * E14 * E12 * E18 * E8 * Math.pow(E4, 1.5)) / (75 * E10 * Math.min(100, Math.sqrt(B8)))))
    const G20 = roundValue(Math.max(((replaceVlaue(B6) * E14 * E12 * E18 * E8 * Math.pow(E4, 1.5)) / (75 * E10 * Math.min(100, Math.sqrt(B8)))), 8 * E4, 6), 0)

    let response = []
    replaceVlaue(B6) > 60000 && response.push({ name: "Provide at least 0.5 <i style='font-size:18px'>d<sub style='font-size:12px'>b</sub></i> transverse reinforcement where <i>f<sub>y</sub></i> ≥ 80,000 psi and center-to-center bar spacing is less than 6 in. (ACI 25.4.2.2)", value: "", suffix: "", message: "", middle: true })
    const output = [{ name: "db =", value: E4, suffix: "in.", message: "" }, { name: "ψc =", format: 2, value: E8, suffix: "", message: "Factor used to modify development length based on concrete strength (ACI Table 25.4.4.3)" }, { name: "λ =", format: 2, value: E10, suffix: "", message: "Factor used to modify development length based on reduced properties of lightweight concrete (ACI Table 25.4.4.3)" }, { name: "ψp =", format: 1, value: E12, suffix: "", message: "Factor used to modify development length based on parallel tie reinforcement (ACI Table 25.4.4.3)" }, { name: "ψe =", format: 1, value: E14, suffix: "", message: "Factor used to modify development length based on bar coating (ACI Table 25.4.4.3)" }, { name: "ψo =", format: 2, value: E18, suffix: "", message: "Factor used to modify development length based on bar location (ACI Table 25.4.4.3)" }, { name: "Development length for headed deformed bars 𝓁<sub>dt</sub> =", value: G20, suffix: "in.", top: true }]

    return [...response, ...output];

}

export const aci2549 = (data) => {
    const [B4, B6, B8, B10, B12, B14, B16, B18, B20] = data;
    // console.log("replaceVlaue(B6)  :  ",replaceVlaue(B6))
    // return;
    //const G22 = ((B4 === "#14" || B4 === "#18") ? "NA" : Math.round((B8 >= 3000 ? (replaceVlaue(B6) <= 60000 ? Math.max(0.0005 * replaceVlaue(B6) * E4, 12) : (replaceVlaue(B6) > 80000 ? Math.max(((0.0009 * replaceVlaue(B6)) - 24) * E4, B20) : Math.max(((0.0009 * replaceVlaue(replaceVlaue(B6))) - 24) * E4, 12))) : (replaceVlaue(B6) <= 60000 ? Math.max(1.33 * 0.0005 * replaceVlaue(B6) * E4, 1.33 * 12) : (replaceVlaue(B6) > 80000 ? Math.max(((0.0009 * replaceVlaue(B6)) - 24) * E4 * 1.33, 1.33 * B20) : Math.max(((0.0009 * replaceVlaue(B6)) - 24) * E4 * 1.33, 1.33 * 12)))), 0))

    // return;
    const E4 = rebarData[B4]
    console.log("E4 : ", E4)
    const E10 = (B10 === "Yes" ? 0.75 : 1)
    console.log("E10 : ", E10)

    const E18 = ((B12 === "Yes" || B14 === "Yes" || B16 === "Yes" || B18 === "Yes") ? 0.75 : 1)
    console.log("E10 : ", E10)

    const valWithoutRound = Math.max((replaceVlaue(B6) * E18 * E4) / (50 * E10 * Math.min(100, Math.sqrt(B8))), 0.0003 * replaceVlaue(B6) * E18 * E4, 8)
    console.log("E10 : ", E10)

    const G21 = roundValue(valWithoutRound, 0)
    console.log("E10 : ", E10)

    const G22 = ((B4 === "#14" || B4 === "#18") ? "NA" : Math.round((B8 >= 3000 ? (replaceVlaue(B6) <= 60000 ? Math.max(0.0005 * replaceVlaue(B6) * E4, 12) : (replaceVlaue(B6) > 80000 ? Math.max(((0.0009 * replaceVlaue(B6)) - 24) * E4, B20) : Math.max(((0.0009 * replaceVlaue(replaceVlaue(B6))) - 24) * E4, 12))) : (replaceVlaue(B6) <= 60000 ? Math.max(1.33 * 0.0005 * replaceVlaue(B6) * E4, 1.33 * 12) : (replaceVlaue(B6) > 80000 ? Math.max(((0.0009 * replaceVlaue(B6)) - 24) * E4 * 1.33, 1.33 * B20) : Math.max(((0.0009 * replaceVlaue(B6)) - 24) * E4 * 1.33, 1.33 * 12)))), 0))
    console.log("G22 : ", G22)
    //return;

    let response = []
    replaceVlaue(B6) > 60000 && response.push({ name: "Provide at least 0.5 <i style='font-size:18px'>d<sub style='font-size:12px'>b</sub></i> transverse reinforcement where <i>f<sub>y</sub></i> ≥ 80,000 psi and center-to-center bar spacing is less than 6 in. (ACI 25.4.2.2)", value: "", suffix: "", message: "", middle: true })
    const output = [{ name: "db =", value: E4, suffix: "in.", message: "" }, { name: "λ =", format: 2, value: E10, suffix: "", message: "Factor used to modify development length based on reduced properties of lightweight concrete (ACI Table 25.4.9.3)" }, { name: "ψr =", format: 1, value: E18, suffix: "", message: "Factor used to modify development length based on confining reinforcement (ACI Table 25.4.9.3)" }, { name: "Compression development length for deformed bars 𝓁<sub>dc</sub> =", value: G21, suffix: "in.", top: true }, { name: "Compression lap splice length 𝓁<sub>sc</sub> =", value: G22, suffix: "in.", top: true }]

    return [...response, ...output];

}
const roundValue = (value) => {
    if (value % 1 > 0) {
        return ((value / 1) - value % 1) + 1
    } else {
        return value
    }
}
export const aci25424 = (data) => {
    const [B4, B6, B8, B10, B12, B14, B16, B18, B20, B22, B24, B26, B28, B30, B32] = data;
    console.log("data  :  ", data)
    const E4 = rebarData[B4]
    // const B22Rebar = 2.257
    // if (rebarData[B22]) {
    //     B22Rebar = rebarData[B22]
    // }

    const E5 = ((B4 === "#7" || B4 === "#8" || B4 === "#9" || B4 === "#10" || B4 === "#11" || B4 === "#14" || B4 === "#18") ? 1 : 0.8)
    const E6 = (replaceVlaue(B6) === 80000 ? 1.15 : (replaceVlaue(B6) === 100000 ? 1.3 : 1))
    const E10 = (B10 === "Yes" ? 0.75 : 1)
    const E14 = (B12 === "No" ? 1 : ((B12 === "Yes" && B14 === "Yes") ? 1.3 : 1))
    const E16 = (B16 === "No" ? 1 : ((B30 < (3 * E4) || (B32 - E4) < (6 * E4)) ? 1.5 : 1.2))
    const E18 = (E14 * E16 >= 1.7 ? 1.7 : E14 * E16)
    const E28 = ((B18 === "Yes" || B20 === "No") ? 0 : (40 * B24) / ((B26 * B28) || 1))
    console.log("E14  :  ", E14)
    console.log("E16  :  ", E16)
    console.log("E18  :  ", E18)


    const val1 = rebarData[B22] + (E4 / 2) + Number(B30)
    const val2 = Number(B32) / 2


    const part1 = Math.min(val1, val2)
    const part2 = Math.min(((E4 / 2) + Number(B30)), B32 / 2)


    let E32 = (B20 === "Yes" ? part1 : part2)
    console.log("E32  :  ", E32)
    let e32Val = E32;
    const array = ("" + E32).split(".")
    console.log("array  :  ", array)

    if (array.length > 1) {
        console.log("inside length greater   :  ")

        if (array[1].length > 1) {
            console.log("array[1]  :  ", array[1])

            const number = Number(array[1].substring(1, 2))
            console.log("number  :  ", number)

            if (number > 0) {
                console.log("number greater  :  ")

                const stringNumber = array[0] + "." + (Number(array[1].substring(0, 1)) + 1)
                console.log("stringNumber   :  ", stringNumber)

                e32Val = Number(stringNumber)
                console.log("E32  :  ", e32Val)

            }
        }
    }
    //const E32 = 1.16

    const E34 = Math.min(2.5, (E32 + E28) / E4)
    const E34Val = Math.min(2.5, (e32Val + E28) / E4)

    // console.log("B6  :  ", B6)
    // console.log("E18  :  ", E18)
    // console.log("E5  :  ", E5)
    // console.log("E6  :  ", E6)
    // console.log("E4  :  ", E4)
    // console.log("E10  :  ", E10)
    // console.log("B8  :  ", B8)
    // console.log("E34  :  ", E34)
    // console.log("E28  :  ", E28)
    // console.log("part1  :  ", part1)
    // console.log("part2  :  ", part2)
    // console.log("B30  :  ", B30)
    // console.log("B32  :  ", B32)
    // console.log("E4  :  ", E4)



    const G36 = roundValue(Math.max(((3 * replaceVlaue(B6) * E18 * E5 * E6 * E4) / (40 * E10 * Math.min(100, Math.sqrt(B8)) * E34)), 12), 0)
    const G37 = ((B4 === "#14" || B4 === "#18") ? "N/A" : G36)
    const G38 = ((B4 === "#14" || B4 === "#18") ? "N/A" : roundValue(Math.max(((1.3 * 3 * replaceVlaue(B6) * E18 * E5 * E6 * E4) / ((40 * E10 * Math.min(100, Math.sqrt(B8)) * E34)) || 1), 12), 0))
    let response = []
    replaceVlaue(B6) > 60000 && response.push({ name: "Provide at least 0.5 <i style='font-size:18px'>d<sub style='font-size:12px'>b</sub></i> transverse reinforcement where <i>f<sub>y</sub></i> ≥ 80,000 psi and center-to-center bar spacing is less than 6 in. (ACI 25.4.2.2)", value: "", suffix: "", message: "", middle: true })
    const output = [{ name: "db =", value: E4, suffix: "in.", message: "" }, { name: "ψs =", format: 1, value: E5, suffix: "", message: "Factor used to modify development length based on reinforcement size (ACI Table 25.4.2.5)" }, { name: "ψg =", format: 1, value: E6, suffix: "", message: "Factor used to modify development length based on reinforcement grade (ACI Table 25.4.2.5)" }, { name: "λ =", format: 2, value: E10, suffix: "", message: "Factor used to modify development length based on reduced properties of lightweight concrete (ACI Table 25.4.2.5)" }, { name: "ψt =", format: 1, value: E14, suffix: "", message: "Factor used to modify development length based on reinforcement location (ACI Table 25.4.2.5)" }, { name: "ψe =", format: 1, value: E16, suffix: "", message: "Factor used to modify development length based on bar coating (ACI Table 25.4.2.5)" }, { name: "ψtψe =", format: 2, value: E18, suffix: "", message: "Footnote [1] in ACI Table 25.4.2.5" }, { name: "Ktr =", format: 1, value: E28, suffix: "in" }, { name: "cb =", format: 1, value: e32Val, suffix: "in" }, { name: "(cb + Ktr)/db =", format: 1, value: E34Val, suffix: "" }, { name: "Tension development length for deformed bars 𝓁<sub>d</sub>  =", value: G36, suffix: "in", top: true }, { name: "Class A tension lap splice length 𝓁<sub>st</sub> =", value: G37, suffix: "in", top: true }, { name: "Class B tension lap splice length 𝓁<sub>st</sub> =", value: G38, suffix: "in", top: true }]
    console.log("output  :  ", output)
    return [...response, ...output];


}
const format = (format, value) => {
    if (format) {
        return "" + Number(value).toFixed(format)
    } else {
        return value
    }
}
export const aashto510824 = (data) => {
    const [B4, B6, B8, B10, B12, B14, B16, B18, B20, B22, B24, B26, B28] = data;

    const E4 = rebarData[B4]
    const E16 = (B8 === "No" ? 1 : (B12 === "Yes" ? Math.min(4.7 * B14 / Math.sqrt(B10), 1) : (B16 < 0.1 ? 0.75 : Math.min(7.5 * B16, 1))))
    const E20 = ((B18 === "Yes" || B20 === "Yes") ? 0.8 : 1)
    const E22 = (B22 === "No" ? 1 : 1.2)
    const E28 = (B24 === "No" ? 1 : B26 / B28)
    const G31 = (38 * E4 * replaceVlaue(B6)) / (60 * Math.sqrt(B10))
    const G32 = roundValue(Math.max(G31 * E20 * E22 * E28 / E16, 8 * E4, 6), 0)
    const output = [{ name: "db =", value: E4, suffix: "in.", message: "" }, { name: "λ =", format: 2, value: E16, suffix: "", message: "Concrete density modification factor (Article 5.4.2.8)" }, { name: "λrc =", format: 2, value: E20, suffix: "", message: "Reinforcement confinement factor (Article 5.10.8.2.4b)" }, { name: "λcw =", format: 2, value: E22, suffix: "", message: "Coating factor (Article 5.10.8.2.4b)" }, { name: "λer =", format: 2, value: E28, suffix: "", message: "Excess reinforcement factor (Article 5.10.8.2.4b)" }, { name: "Basic development length for deformed bars in tension with a standard hook 𝓁<sub>hb</sub> =", value: format(1, G31), suffix: "in", top: true }, { name: "Modified development length for deformed bars in tension with a standard hook 𝓁<sub>dh</sub> =", value: G32, suffix: "in", top: true }, { name: "", note: true, message: (Number(B6) === 80 || Number(B6) === 100) ? "NOTE : For hooked bars having a specified minimum yield strength greater than 75 ksi, ties satisfying the requirements of Article 5.10.8.2.4c must be provided (Article 5.10.8.2.4)." : "NOTE : For normal weight concrete with design concrete compressive strenghs between 10 and 15 ksi, development length of hooked bars must be enclosed with #3 or larger ties or stirrups along the full development length at a spacing not greater than 3 bar diameters. A minimum of three ties or stirrups must be provided  (Article 5.10.8.2.4c)." }]
    return output;
}

export const aashto510822 = (data) => {
    const [B4, B6, B8, B10, B12, B14, B16, B18] = data;

    const E4 = rebarData[B4]
    const E10 = (B10 === "No" ? 1 : 0.75)
    const E12 = (B12 === "No" ? 1 : B14 / B16)
    const E14 = (B8 < 3 ? 1.33 : (B10 === "Yes" ? 0.75 : (B18 === "Yes" ? 0.83 : 1)))

    console.log("B14  : ", data)

    const G19 = Math.max(0.63 * E4 * replaceVlaue(B6) / Math.sqrt(B8), 0.3 * E4 * replaceVlaue(B6))
    const G20 = roundValue(Math.max(G19 * E10 * E12, 8), 0)
    const G21 = roundValue(Math.max((replaceVlaue(B6) <= 60 ? 0.5 * E14 * replaceVlaue(B6) * E4 : ((0.9 * replaceVlaue(B6)) - 24) * E14 * E4), 12), 0)
    const output = [{ name: "db =", value: E4, suffix: "in.", message: "" }, { name: "λrc =", format: 2, value: E10, suffix: "", message: "Reinforcement confinement factor (Article 5.10.8.2.2a)" }, { name: "λer =", format: 2, value: E12, suffix: "", message: "Excess reinforcement factor (Article 5.10.8.2.2a)" }, { name: "m =", format: 2, value: E14, suffix: "", message: "(Article 5.10.8.4.5a)" }, { name: "Basic compressive development length for deformed bars 𝓁db =", value: format(1, G19), suffix: "in", top: true }, { name: "Modified compressive development length for deformed bars 𝓁<sub>d</sub> =", value: G20, suffix: "in", top: true }, { name: "Compression lap splice length 𝓁<sub>c</sub> =", value: G21, suffix: "in", top: true }]
    return output;

}

export const aashto510821 = (data) => {
    const [B4, B6, B8, B10, B12, B14, B16, B18, B20, B22, B24, B26, B28, B30, B32, B34, B36, B38, B40, B42, B44] = data;
    console.log("datadata  :  ", data)
    const E4 = rebarData[B4]
    const E16 = (B8 === "No" ? 1 : (B12 === "Yes" ? Math.min(4.7 * B14 / Math.sqrt(B10), 1) : (B16 < 0.1 ? 0.75 : Math.min(7.5 * B16, 1))))
    const E20 = (B18 === "No" ? 1 : (B20 === "Yes" ? 1.3 : (B10 > 10 ? 1.3 : 1)))
    const E22 = (B22 === "No" ? 1 : ((parseFloat(B36) < (3 * E4) || (parseFloat(B38) - E4) < (6 * E4)) ? 1.5 : 1.2))
    const E24 = (E20 * E22 >= 1.7 ? 1.7 : E20 * E22)
    const E34 = ((B24 === "Yes" || B26 === "No") ? 0 : (40 * B30) / (B32 * B34))
    const E36 = (B26 === "Yes" ? Math.min((E4 / 2) + parseFloat(B36) + rebarData[B28], parseFloat(B38) / 2) : Math.min((E4 / 2) + parseFloat(B36), parseFloat(B38) / 2))
    const E38 = Math.min(Math.max(E4 / (E36 + E34), 0.4), 1)
    const E44 = (B40 === "No" ? 1 : B42 / B44)
    const G46 = 2.4 * E4 * replaceVlaue(B6) / Math.sqrt(B10)
    console.log("G46 : ", G46)
    console.log("E24 : ", E24)
    console.log("E38 : ", E38)
    console.log("E44 : ", E44)
    console.log("E16 : ", E16)

    const G47 = roundValue(Math.max(G46 * E24 * E38 * E44 / E16, 12), 0)
    console.log("G47  :  ", G47)
    const G48 = Math.max(G47, 12)
    const G49 = roundValue(Math.max(1.3 * G46 * E24 * E38 * E44 / E16, 12), 0)
    const output = [{ name: "db =", value: E4, suffix: "in.", message: "" }, { name: "λ =", format: 2, value: E16, suffix: "", message: "Concrete density modification factor (Article 5.4.2.8)" }, { name: "λrl =", format: 2, value: E20, suffix: "", message: "Reinforcement location factor (Article 5.10.8.2.1b)" }, { name: "λcf =", format: 2, value: E22, suffix: "", message: "Coating factor (Article 5.10.8.2.1b)" }, { name: "λrlλcf =", format: 1, value: E24, suffix: "", message: "(Article 5.10.8.2.1b)" }, { name: "ktr =", format: 1, value: E34, suffix: "in", message: "Transverse reinforcement index (Article 5.10.8.2.1c)" }, { name: "Cb =", value: E36, suffix: "in", message: "", format: 2 }, { name: "λrc =", value: E38, suffix: "", format: 2, message: "Reinforcement confinement factor (Article 5.10.8.2.1c)" }, { name: "λer =", format: 2, value: E44, suffix: "", message: "Excess reinforcement factor (Article 5.10.8.2.1c)" }, { name: "Basic tension development length for deformed bars 𝓁<sub>db</sub>  =", value: G46.toFixed(1), suffix: "in", top: true }, { name: "Modified tension development length for deformed bars 𝓁<sub>d</sub>  =", value: G47, suffix: "in", top: true }, { name: "Class A tension lap splice length =", value: G48, suffix: "in", top: true }, { name: "Class B tension lap splice length =", value: G49, suffix: "in", top: true }, { name: "", note: true, message: (Number(B6) === 80 || Number(B6) === 100) ? "NOTE: Transverse reinforcement satisfying Article 5.7.2.5 for beams and Article 5.10.4.3 for columns must be provided over the required development / lap splice length where the specified yield strength > 75 ksi(Articles 5.10.8.2.1 and 5.10.8.4.3a)." : "NOTE : Transverse reinforcement consisting of at least #3 bars at 12.0-in. centers must be provided along the development/lap splice length where the concrete compressive strength > 10 ksi. A min. of 3 bars must be provided (Articles 5.10.8.2.1 and 5.10.8.4.3)." }]
    return output;
}