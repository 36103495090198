import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { authLoginSucess } from '../actions/auth'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppBar, Button, Card, Toolbar } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { aci25423, aci25424, generateReportAci25423, generateReportAci25424 } from '../formulas/formulas';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
const doc = new jsPDF();


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 100,
        //maxWidth: '80%'
    },
    holder: {
        width: '100%',
        height: "100dv",
        backgroundColor: '#367DB5',
    },
    root: {
        justifyContent: 'center',
    },
    div: {
        justifyContent: 'space-around',
        // backgroundColor: 'red',
        alignItems: 'center',
        marginTop: 15,
        flexDirection: 'row'
    },
    divBottom: {
        justifyContent: 'space-around',
        // backgroundColor: 'red',
        alignItems: 'center',
        marginTop: 5,
        flexDirection: 'row'
    },
    card: {
        flexDirection: 'column',
        width: '100%',
        padding: 15,
        alignSelf: 'center',
        borderWidth: 5

    },
    div1: {
        //  display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 5,
        fontSize: 17,
        justifyContent: 'center',
        alignItems: 'center'

    },
    div2: {
        display: 'flex',
        width: '100%',
        color: '#FFFFFF'

    },
    name: {
        // flex: .9,
        color: '#FFFFFF'

    },
    value: {
        // flex: .1
        marginLeft: 20,
        color: '#FFFFFF'
    },
    nameHeader: {
        color: '#367DB5'
    },
    valueHeader: {
        marginLeft: 20,
        color: '#367DB5'
    },
    topAppBar: {
        top: 0,
        bottom: 'auto',
        backgroundColor: 'white'
    },
    title: {
        flexGrow: 1,
        color: '#7E7E7E',
        width: '100%',

    },
    container: {
        backgroundColor: '#367DB5',
        padding: theme.spacing(12)
    }, img: {
        width: 200,
        resizeMode: 'contain',
        alignSelf: 'center'

    }
}));

const ReportComponentScreen = (props) => {
    const { authLoginSucess, state, output } = props
    const classes = useStyles();
    //const { state } = props.location
    const [middle, setmiddle] = useState(false)
    const [rows, setRows] = useState(null)
    const [generating, setGenerating] = useState(false)
    // const [rows, setRows] = useState(null)

    // const { state } = useLocation();
    // const { id, color } = state;
    useEffect(() => {
        //alert(JSON.stringify(state))
        console.log("state :  ", state)
        console.log("output :", JSON.stringify(output))
        //alert(output)
        calculateData(state)
    }, [])

    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }


    const calculateData = async (data) => {
        let output = []
        let array = []
        let object = data;
        let id = object.id;
        delete object.id
        Object.keys(object).forEach(function (key) {
            console.log("values[key]  :  ", object[key])
            if (object.hasOwnProperty(key)) {
                if (typeof (object[key]) === 'string') {
                    if (object[key].includes(",")) {
                        // console.log("includes , ", values[key])
                        array.push(parseFloat(object[key].replace(/,/g, '')))
                    } else {
                        if (isNaN(parseFloat(object[key]))) {
                            array.push(object[key].replace(/,/g, ''))
                        } else {
                            array.push(parseFloat(object[key]))
                        }
                    }
                } else
                    array.push(object[key])
            }
        });
        if (window.location.href.includes("25_4_2_3")) {

            let length = ["#3", "#4", "#5", "#6", "#7", "#8", "#9", "#10", "#11", "#14", "#18"]
            const report = generateReportAci25423(array)
            console.log("report : ", JSON.stringify(report))
            const dataForRows = report && report.map((item, index) => {
                //console.log("item : ", item)
                if (state["1"] === "60,000 psi") {
                    // alert("Hello")
                    return createData(length[index], item[6]?.value, item[7]?.value, item[8]?.value)
                }
                else
                    return createData(length[index], item[7]?.value, item[8]?.value, item[9]?.value)

            })
            //console.log("dataForRows : ", JSON.stringify(dataForRows))
            setRows(dataForRows);
        } else if (window.location.href.includes("25_4_2_4")) {
            let length = ["#3", "#4", "#5", "#6", "#7", "#8", "#9", "#10", "#11", "#14", "#18"]

            const report = generateReportAci25424(array)
            const dataForRows = report && report.map((item, index) => {
                //return createData(length[index], item[10]?.value, item[11]?.value, item[12]?.value)
                if (state["1"] === "60,000 psi")
                    return createData(length[index], item[10]?.value, item[11]?.value, item[12]?.value)
                else
                    return createData(length[index], item[11]?.value, item[12]?.value, item[13]?.value)
            })
            setRows(dataForRows);
        }
        //setOutputData(output)
    }
    const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))
    const generatePdf = () => {
        const enteredName = prompt('Please enter report name')

        if (enteredName === null) {
            return
        } else {
            if (enteredName.trim().length === 0) {
                alert("Please enter a vaild name .")
                return
            }
        }

        setGenerating(true)
        setTimeout(() => {
            const pdf = new jsPDF();
            // pdf.addImage('../logo-with-text.png', 'JPEG', 45, 10);
            toDataURL('https://i.ibb.co/r7grPXS/logo-with-text.png')
                .then(dataUrl => {
                    console.log('RESULT:', dataUrl)
                    pdf.addImage(dataUrl, 'JPEG', 5, 10, 55, 15);
                    pdf.setFontSize(10);
                    pdf.setTextColor("#367DB5");
                    pdf.text("933 North Plum Grove Road | Schaumburg, IL 60173-4758 | Tel. 847.517.1200 | Fax 847.517.1206 | www.crsi.org", 5, 32);

                    pdf.setFontSize(14);
                    pdf.setTextColor("#000000");
                    const clearCoverValue = window.location.href.includes("25_4_2_3") ? state["7"] : state["13"]
                    const clearCover = "Clear cover to longitudinal bars being developed = " + clearCoverValue + " in."

                    const clearSpacingValue = window.location.href.includes("25_4_2_3") ? state["8"] : state["14"]
                    const clearSpacing = window.location.href.includes("25_4_2_3") ? "Clear spacing between" + " longitudinal bars being developed = " + clearSpacingValue+" in.": "Center-to-center spacing of the" + " longitudinal bars being developed = " + clearSpacingValue+" in."
                    const headerStyle = 'style="padding: 15px;font-size: 18px;text-align: center;';
                    pdf.setFontSize(16);
                    pdf.setTextColor("#367DB5");
                    pdf.text(enteredName, 10, 45);
                    pdf.setFontSize(14);
                    pdf.setTextColor("#000000");
                    pdf.text(window.location.href.includes("25_4_2_3") ? "ACI 318 reference: " + "ACI 25.4.2.3" : "ACI 318 reference: " + "ACI 25.4.2.4", 10, 53);
                    const weight = state["3"] === "Yes" ? "Lightweight" : "Normalweight"
                    
                    pdf.text(`Concrete: ${weight}, f'`, 10, 61);
                    pdf.text("c", state["3"] === "Yes" ? 62 : 67, 63);
                    pdf.text(" = " + state["2"] + " psi", state["3"] === "Yes" ? 64 : 69, 61);

                    pdf.text("Reinforcing steel: f", 10, 69);
                    pdf.text("y", 51, 71);
                    pdf.text(" = " + state["1"], 53, 69);

                    const coating = state["6"] === 'Yes' ? "Coated bars" : "Uncoated bars"
                    pdf.text(coating, 47, 77);
                    const isTopbar = (state["4"] === 'Yes' && state["5"] === 'Yes') ? "Top bars" : "Other than top bars"
                    pdf.text(isTopbar, 47, 85);
                    // { window.location.href.includes("25_4_2_4") && <div style={{ color: generating ? "black" : 'white', fontSize: 16, marginBottom: 5 }}>Transverse reinforcement index, K<sub>tr</sub> = {Number.parseFloat(output.value[7].value).toFixed(1)} in.</div> }

                    const codeMinimum = state["9"] === 'Yes' ? "Code minimum stirrups or ties are provided over the development length" : "Code minimum stirrups or ties are not provided over the development length"
                    window.location.href.includes("25_4_2_4") && pdf.text("Transverse reinforcement index, K", 10, 93);
                    window.location.href.includes("25_4_2_4") && pdf.text("tr", 86, 95);
                    window.location.href.includes("25_4_2_4") && pdf.text(`= ${Number.parseFloat(output.value[7].value).toFixed(1)} in.`, 89, 93);

                    window.location.href.includes("25_4_2_4") && pdf.text(clearCover, 10, 101);
                    window.location.href.includes("25_4_2_4") && pdf.text(clearSpacing, 10, 109);
                    window.location.href.includes("25_4_2_3") && pdf.text(clearCover, 10, 93);
                    window.location.href.includes("25_4_2_3") && pdf.text(clearSpacing, 10, 101);
                    window.location.href.includes("25_4_2_3") && pdf.text(codeMinimum, 10, 109);

                    pdf.setFontSize(10);
                    // doc.maxWidth(50)
                    pdf.text("Bar Size", 10, 117, { maxWidth: 25 });
                    pdf.text("Tension Development Length (in.), l", 30, 117, { maxWidth: 50 });
                    pdf.text("d", 39, 122, { maxWidth: 60 });
                    pdf.text("Class A Tension Lap Splice Length (in.), l", 85, 117, { maxWidth: 50 });
                    pdf.text("st", 106, 122, { maxWidth: 60 });
                    pdf.text("Class B Tension Lap Splice Length (in.), l", 140, 117, { maxWidth: 50 });
                    pdf.text("st", 161, 122, { maxWidth: 60 });
                    pdf.line(10, 125, 190, 125)
                    const rowsY = 133
                    pdf.setFontSize(12);
                    let y = 0
                    for (let i = 0; i < rows.length; i++) {
                        y = rowsY + i * 8
                        pdf.text(" " + rows[i].name + "" || "", 10, rowsY + i * 8, { maxWidth: 25, align: 'left', renderingMode: "fill" });
                        pdf.text("   " + rows[i].calories + "" || "", 30, rowsY + i * 8, { maxWidth: 50, align: 'left' });
                        pdf.text("   " + rows[i].fat + "" || "", 85, rowsY + i * 8, { maxWidth: 50, align: 'left' });
                        pdf.text("   " + rows[i].carbs + "" || "", 140, rowsY + i * 8, { maxWidth: 50, align: 'left' });
                        pdf.line(10, rowsY + i * 8 + 2, 190, rowsY + i * 8 + 2)
                        console.log("row : ", rows[i])
                    }
                    pdf.setFontSize(10);
                    pdf.text("WEBSITE : ", 5, 290);
                    pdf.setTextColor("#367DB5");
                    pdf.text("https://crsidesktopcalculator.com", 25, 290);

                    pdf.output('dataurlnewwindow');
                    pdf.save(enteredName + ".pdf");
                    setGenerating(false)

                })

        }, 1000)

    }
    const format = (format, value) => {
        if (format) {
            console.log("inside format", "" + Number(value).toFixed(format))
            return "" + Number(value).toFixed(format)
        } else {
            console.log("inside non format", "" + Number(value).toFixed(format))
            console.log("inside non format value", "" + value)
            if (isNaN(value)) {
                console.log("inside nan", "" + value)
                return "" + "0"
            }
            else {
                console.log("inside not nan", "" + value)
                return value
            }
        }
    }
    return (
        <div style={{
            // display: 'flex',
            height: 1000,
            justifyContent: 'center',
            backgroundColor: "#367DB5",
            paddingTop: 70,

        }}>
            {console.log("state  :  ", JSON.stringify(state))}
            <div
                id="hello"
                style={{ paddingTop: 20 }}
            >
                <div>
                    {/* <img
                        id="logo"
                        style={{
                            width: 140,
                            resizeMode: 'contain',
                            alignSelf: 'center',
                            height: 40
                        }}
                        src="https://i.ibb.co/r7grPXS/logo-with-text.png"
                        alt="image"
                    /> */}
                    {/* <span style={{ color: '#367DB5', marginTop: 10 }}>933 North Plum Grove Road | Schaumburg, IL 60173-4758 | Tel. 847.517.1200 | Fax 847.517.1206 | www.crsi.org</span> */}
                </div>

                {window.location.href.includes("25_4_2_3") && <h2 style={{ color: generating ? "black" : 'white' }}>ACI 318 reference:  ACI 25.4.2.3</h2>}
                {window.location.href.includes("25_4_2_4") && <h2 style={{ color: generating ? "black" : 'white' }}>ACI 318 reference:  ACI 25.4.2.4</h2>}


                {window.location.href.includes("25_4_2_3") && <div style={{ color: generating ? "black" : 'white', fontSize: 16 }}>Concrete: {state["3"] === "Yes" ? "Lightweight," : "Normalweight,"} <i>f'<sub>c</sub></i> = {state["2"]} psi</div>}
                {window.location.href.includes("25_4_2_4") && <div style={{ color: generating ? "black" : 'white', fontSize: 16 }}>Concrete: {state["3"] === "Yes" ? "Lightweight," : "Normalweight,"} <i>f'<sub>c</sub></i> = {state["2"]} psi</div>}

                <div style={{ color: generating ? "white" : 'white', fontSize: 16, marginBottom: 5 }}>Reinforcing steel: <i>f<sub>y</sub></i> = {state["1"]}</div>
                {<div style={{ color: generating ? "white" : 'white', fontSize: 16, marginBottom: 5, marginLeft: 122 }}>{state["6"] === 'Yes' ? "Coated bars" : "Uncoated bars"}</div>}
                {<div style={{ color: generating ? "white" : 'white', fontSize: 16, marginBottom: 5, marginLeft: 122 }}>{(state["4"] === 'Yes' && state["5"] === 'Yes') ? "Top bars" : "Other than top bars"}</div>}
                {window.location.href.includes("25_4_2_4") && <div style={{ color: generating ? "white" : 'white', fontSize: 16, marginBottom: 5 }}>Transverse reinforcement index, K<sub>tr</sub> = {Number.parseFloat(output.value[7].value).toFixed(1)} in.</div>}


                <div style={{ color: generating ? "black" : 'white', fontSize: 15, marginBottom: 5 }}>Clear cover to longitudinal bars being developed = {window.location.href.includes("25_4_2_3") ? state["7"] : state["13"]} in.</div>
                {window.location.href.includes("25_4_2_3") && <div style={{ color: generating ? "black" : 'white', fontSize: 16, marginBottom: 5 }}>Clear spacing between longitudinal bars being developed = {state["8"]} in.</div>}
                {window.location.href.includes("25_4_2_4") && <div style={{ color: generating ? "black" : 'white', fontSize: 16, marginBottom: 5 }}>Center-to-center spacing of the longitudinal bars being developed = {state["14"]} in.</div>}
                {window.location.href.includes("25_4_2_3") && <div style={{ color: generating ? "black" : 'white', fontSize: 16, marginBottom: 5 }}>{state["9"] === 'Yes' ? "Code minimum stirrups or ties are provided over the development length" : "Code minimum stirrups or ties are not provided over the development length"}</div>}


                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Bar Size</TableCell>
                                <TableCell align="right">Tension Development Length (in.), <i>𝓁<sub>d</sub></i></TableCell>
                                <TableCell align="right">Class A Tension Lap Splice Length (in.), <i>𝓁<sub>st</sub></i></TableCell>
                                <TableCell align="right">Class B Tension Lap Splice Length (in.), <i>𝓁<sub>st</sub></i></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows && rows.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.calories}</TableCell>
                                    <TableCell align="right">{row.fat}</TableCell>
                                    <TableCell align="right">{row.carbs}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
            <Button
                onClick={generatePdf}
                style={{ backgroundColor: "#0072AA", color: 'white', paddingHorizontal: 20, paddingRight: 50, paddingLeft: 50, marginTop: 20 }} color="white">Export to PDF</Button>
        </div>


    );
}
const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    // isLoading: state.global.isLoading
});


const mapDispatchToProps = dispatch => ({
    dispatch,
    ...bindActionCreators(
        {
            //  doLogout: doLogoutAPI,
            authLoginSucess
        }, dispatch),
});
const ReportComponent = connect(mapStateToProps, mapDispatchToProps)(ReportComponentScreen);
export default ReportComponent;
