import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { authLoginSucess } from '../actions/auth'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import { outputHeaderLabel, outputLabel } from '../common/commonLabels';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const useStyles = makeStyles((theme) => ({
    holder: {
        width: '100%',
        height: "100dv",
        backgroundColor: '#367DB5',
    },
    root: {
        justifyContent: 'center',
    },
    div: {
        justifyContent: 'space-around',
        // backgroundColor: 'red',
        alignItems: 'center',
        marginTop: 15,
        flexDirection: 'row'
    },
    divBottom: {
        justifyContent: 'space-around',
        // backgroundColor: 'red',
        alignItems: 'center',
        marginTop: 5,
        flexDirection: 'row'
    },
    card: {
        flexDirection: 'column',
        width: '100%',
        padding: 15,
        alignSelf: 'center',
        borderWidth: 5

    },
    div1: {
        //  display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 5,
        fontSize: 17,
        justifyContent: 'center',
        alignItems: 'center'

    },
    div2: {
        display: 'flex',
        width: '100%',
        color: '#FFFFFF'

    },
    name: {
        // flex: .9,
        color: '#FFFFFF'

    },
    value: {
        // flex: .1
        marginLeft: 20,
        color: '#FFFFFF'
    },
    nameHeader: {
        color: '#367DB5'
    },
    valueHeader: {
        marginLeft: 20,
        color: '#367DB5'
    },
    topAppBar: {
        top: 0,
        bottom: 'auto',
        backgroundColor: 'white'
    },
    title: {
        flexGrow: 1,
        color: '#7E7E7E',
        width: '100%',

    },
    container: {
        backgroundColor: '#367DB5',
        padding: theme.spacing(12)
    }, img: {
        width: 200,
        resizeMode: 'contain',
        alignSelf: 'center'

    }
}));

const ResultScreen = (props) => {
    const { authLoginSucess } = props
    const classes = useStyles();
    const { state } = props.location
    const [middle, setmiddle] = useState(false)
    useEffect(() => {
        // alert(JSON.stringify(state))
        if (state?.value && state.value.length > 0) {
            for (let i = 0; i < state.value.length; i++) {
                if (state.value[i].middle) {
                    setmiddle(true)
                    break
                }
            }
        }
    }, [state?.value])

    const format = (format, value) => {
        if (format) {
            console.log("inside format", "" + Number(value).toFixed(format))
            return "" + Number(value).toFixed(format)
        } else {
            console.log("inside non format", "" + Number(value).toFixed(format))
            console.log("inside non format value", "" + value)
            if (isNaN(value)) {
                console.log("inside nan", "" + value)
                return "" + "0"
            }
            else {
                console.log("inside not nan", "" + value)
                return value
            }
        }
    }
    return (
        <div
            style={{
                backgroundColor: '#367DB5', paddingTop: 100, paddingRight: 2, paddingLeft: 2
            }}
        >
            <div
                style={{
                    backgroundColor: '#FFFFFF', borderRadius: 15
                }}
            >
                {state && state.value?.map((item, index) => <div
                    key={"pdiv2" + index}
                    className={classes.div}>
                    {item.top &&
                        <Card
                            className={classes.card}>
                            <div className={classes.div1}>
                                <span className={classes.nameHeader}>{ReactHtmlParser(item.name)} </span>
                                {/* {outputHeaderLabel(item.name)} */}
                                <span className={classes.valueHeader}>{isNaN(item.value) ? "0" : !isFinite(item.value) ? "2147483647" : format(item.format, item.value)} {item.suffix || ""}{item.suffix === 'in' && "."}</span>
                            </div>
                            {item.message && <div className={classes.div2}>
                                <h5>{item.message}</h5>
                            </div>}
                        </Card>
                    }
                </div>)}
            </div>
            {state && state.value?.map((item, index) => <div
                key={"pdiv1" + index}
                className="row">
                {item.middle &&
                    <div>
                        {item.note ?
                            <div style={{ color: "#F4BC33", marginTop: 10 }}> {item.message}</div>
                            :
                            <Grid
                                style={{ marginTop: 10 }}
                                container spacing={3}>
                                <Grid item xs={12} lg={item.message ? 4 : 12} xl={item.message ? 4 : 12} sm={item.message ? 4 : 12}>
                                    <span className={classes.name}>{ReactHtmlParser(item.name)} </span>
                                    {/* {ReactHtmlParser(item.name)} */}
                                    <span className={classes.value}>{!isFinite(item.value) ? "2147483647" : format(item.format, item.value)} {item.suffix || ""}{item.suffix === 'in' && "."}</span>
                                </Grid>
                                {/* {outputLabel(item.name)} */}
                                {item.message && <Grid item xs={12} lg={8} xl={8} sm={8}>
                                    <span style={{ color: 'white', }}>
                                        {item.message}
                                    </span>
                                </Grid>}
                            </Grid>
                        }
                    </div>

                }
            </div>)}

            {middle && <div style={{ width: '100%', height: 1, backgroundColor: 'white', marginTop: 10, marginBottom: 10 }}></div>}


            {state && state.value?.map((item, index) => <div
                key={"pdiv1" + index}
                className="row">
                {!item.top && !item.middle &&

                    <div>
                        {item.note ?
                            <div style={{ color: "#F4BC33", marginTop: 10 }}> {item.message}</div>
                            :
                            <Grid
                                style={{ marginTop: 10 }}
                                container spacing={3}>
                                <Grid item xs={12} lg={item.message ? 4 : 12} xl={item.message ? 4 : 12} sm={item.message ? 4 : 12}>
                                    {/* <span className={classes.name}>{item.name} </span> */}
                                    {outputLabel(item.name)}
                                    <span className={classes.value}>{!isFinite(item.value) ? "2147483647" : format(item.format, item.value)} {item.suffix || ""}{item.suffix === 'in' && "."}</span>
                                </Grid>
                                {/* {outputLabel(item.name)} */}
                                {item.message && <Grid item xs={12} lg={8} xl={8} sm={8}>
                                    <span style={{ color: 'white', }}>
                                        {item.message}
                                    </span>
                                </Grid>}
                            </Grid>
                        }
                    </div>

                }
                {index === 0 && !middle && <div style={{ width: '100%', height: 1, backgroundColor: 'white', marginTop: 10, marginBottom: 10 }}></div>}
            </div>)}

        </div>


    );
}
const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    // isLoading: state.global.isLoading
});


const mapDispatchToProps = dispatch => ({
    dispatch,
    ...bindActionCreators(
        {
            //  doLogout: doLogoutAPI,
            authLoginSucess
        }, dispatch),
});
const Result = connect(mapStateToProps, mapDispatchToProps)(ResultScreen);
export default Result;
