export const aciLengths = [
    {
        "id": "1",
        "name": "Deformed Bars in Tension ACI 25.4.2.3"
    },
    {
        "id": "2",
        "name": "Deformed Bars in Tension ACI 25.4.2.4"
    },
    {
        "id": "3",
        "name": "Standard Hook in Tension ACI 25.4.3"
    },
    {
        "id": "4",
        "name": "Headed Deformed Bars in Tension ACI 25.4.4"
    },
    {
        "id": "5",
        "name": "Deformed Bars in Compression ACI 25.4.9"
    }
]



export const aashtoLengths = [
    {
        "id": "6",
        "name": "Tension - AASHTO 5.10.8.2.1"
    },
    {
        "id": "7",
        "name": "Hooked - AASHTO 5.10.8.2.4"
    },
    {
        "id": "8",
        "name": "Compression - AASHTO 5.10.8.2.2"
    }
]



export const Deformed_Bars_in_Tension_ACI_25_4_2_3 = {
    "tension": [
        {
            "label": "Longitudinal bar size: *",
            "isMandetory": true,
            "lineCount": 2,
            "answer": "#3",
            "type": "radio",
            "visible": true,
            conditionalNote: true,
            conditionalScript: '(values[0]==="#14" || values[0]==="#18")',
            conditionalScript2: '(values[0]!=="#14" && values[0]!=="#18")',
            condition1: "Note: Tension lap splices are not permitted to be used for #14 and #18 bars (ACI 25.5.1.1)",
            condition2: "",
            "action": true,
            "row": [
                0
            ],
            "options": [
                {
                    "optionLabel": "#3"
                },
                {
                    "optionLabel": "#4"
                },
                {
                    "optionLabel": "#5"
                },
                {
                    "optionLabel": "#6"
                },
                {
                    "optionLabel": "#7"
                },
                {
                    "optionLabel": "#8"
                },
                {
                    "optionLabel": "#9"
                },
                {
                    "optionLabel": "#10"
                },
                {
                    "optionLabel": "#11"
                },
                {
                    "optionLabel": "#14"
                },
                {
                    "optionLabel": "#18"
                }
            ]
        },
        {
            "label": "fy = *",
            "isMandetory": true,
            "lineCount": 2,
            "answer": "60,000 psi",
            "type": "radio",
            "visible": true,
            "note": null,
            "action": false,
            "row": [

            ],
            "options": [
                {
                    "optionLabel": "60,000 psi"
                },
                {
                    "optionLabel": "80,000 psi"
                },
                {
                    "optionLabel": "100,000 psi"
                }
            ]
        },
        {
            "label": "f'c (psi) = *",
            "isMandetory": true,
            "lineCount": null,
            "answer": "",
            "type": "editText",
            "visible": true,
            "note": null,
            "action": false,
            "row": [

            ],
            "options": [

            ]
        },
        {
            "label": "Is lightweight concrete used?",
            "isMandetory": false,
            "lineCount": 1,
            "answer": "No",
            "type": "radio",
            "visible": true,
            "note": null,
            "action": false,
            "row": [

            ],
            "options": [
                {
                    "optionLabel": "Yes"
                },
                {
                    "optionLabel": "No"
                }
            ]
        },
        {
            "label": "Are the bars being developed horizontal?",
            "isMandetory": false,
            "lineCount": 1,
            "answer": "No",
            "type": "radio",
            "visible": true,
            "note": null,
            "action": true,
            "row": [
                5
            ],
            "options": [
                {
                    "optionLabel": "Yes"
                },
                {
                    "optionLabel": "No"
                }
            ]
        },
        {
            "label": "Is more than 12 in. of fresh concrete placed below the horizontal reinforcement?",
            "isMandetory": false,
            "lineCount": 1,
            "answer": "No",
            "type": "radio",
            "visible": false,
            "note": null,
            "action": false,
            script: '(values[4]==="Yes")',
            "row": [

            ],
            "options": [
                {
                    "optionLabel": "Yes"
                },
                {
                    "optionLabel": "No"
                }
            ]
        },
        {
            "label": "Are the bars epoxy-coated or zinc and epoxy dual-coated?",
            "isMandetory": false,
            "lineCount": 1,
            "answer": "No",
            "type": "radio",
            "visible": true,
            "note": null,
            "action": false,
            "row": [

            ],
            "options": [
                {
                    "optionLabel": "Yes"
                },
                {
                    "optionLabel": "No"
                }
            ]
        },
        {
            "label": "Clear cover to longitudinal bars being developed. (Enter # in inches)",
            "isMandetory": false,
            "lineCount": null,
            "answer": "0.00",
            "hint": "0.00",
            "type": "editText",
            "visible": true,
            "note": null,
            "action": false,
            decimalValue: 2,
            decimal: true,
            "row": [

            ],
            "options": [

            ]
        },
        {
            "label": "Clear spacing between longitudinal bars being developed. (Enter # in inches)",
            "isMandetory": false,
            "lineCount": null,
            "answer": "0.00",
            "hint": "0.00",
            "type": "editText",
            "visible": true,
            "note": null,
            "action": false,
            decimalValue: 1,
            decimal: true,
            "row": [

            ],
            "options": [

            ]
        },
        {
            "label": "Are stirrups or ties that are not less than the Code minimum provided over the development length?",
            "isMandetory": false,
            "lineCount": 1,
            "answer": "No",
            "type": "radio",
            "visible": true,
            "note": null,
            "action": false,
            "row": [

            ],
            "options": [
                {
                    "optionLabel": "Yes"
                },
                {
                    "optionLabel": "No"
                }
            ]
        }
    ]
}

export const Deformed_Bars_in_Tension_ACI_25_4_2_4 = [
    {
        "label": "Longitudinal bar size: *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "#3",
        "type": "radio",
        "visible": true,
        conditionalNote: true,
        conditionalScript: '(values[0]==="#14" || values[0]==="#18")',
        conditionalScript2: '(values[0]!=="#14" && values[0]!=="#18")',
        condition1: "Note: Tension lap splices are not permitted to be used for #14 and #18 bars (ACI 25.5.1.1)",
        condition2: "",
        "note": "",
        "action": true,
        "row": [
            1
        ],
        "options": [
            {
                "optionLabel": "#3"
            },
            {
                "optionLabel": "#4"
            },
            {
                "optionLabel": "#5"
            },
            {
                "optionLabel": "#6"
            },
            {
                "optionLabel": "#7"
            },
            {
                "optionLabel": "#8"
            },
            {
                "optionLabel": "#9"
            },
            {
                "optionLabel": "#10"
            },
            {
                "optionLabel": "#11"
            },
            {
                "optionLabel": "#14"
            },
            {
                "optionLabel": "#18"
            }
        ]
    },
    {
        "label": "fy = *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "60,000 psi",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "60,000 psi"
            },
            {
                "optionLabel": "80,000 psi"
            },
            {
                "optionLabel": "100,000 psi"
            }
        ]
    },
    {
        "label": "f'c (psi) = *",
        "isMandetory": true,
        "lineCount": null,
        "answer": "",
        "type": "editText",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Is lightweight concrete used?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Are the bars being developed horizontal?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": true,
        "row": [
            5
        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Is more than 12 in. of fresh concrete placed below the horizontal reinforcement?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": false,
        script: '(values[4]==="Yes")',
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Are the bars epoxy-coated or zinc and epoxy dual-coated?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Assume Ktr = 0?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": true,
        "row": [
            10,
            11,
            12
        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Is transverse reinforcement provided?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": true,
        "row": [
            9,
            10,
            11,
            12
        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Transverse bar size:",
        "isMandetory": false,
        "lineCount": 2,
        "answer": "No",
        "type": "radio",
        "visible": false,
        "note": null,
        "action": false,
        script: '(values[8]==="Yes")',
        "row": [

        ],
        "options": [
            {
                "optionLabel": "#3"
            },
            {
                "optionLabel": "#4"
            },
            {
                "optionLabel": "#5"
            },
            {
                "optionLabel": "#6"
            },
            {
                "optionLabel": "#7"
            },
            {
                "optionLabel": "#8"
            }
        ]
    },
    {
        "label": "Total area of transverse reinforcement Atr within spacing s (sq in) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": false,
        "note": null,
        decimalValue: 2,
        decimal: true,
        "action": false,
        script: '(values[7]==="No" && values[8]==="Yes")',
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Spacing of transverse reinforcement, s (in.) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": false,
        decimalValue: 2,
        decimal: true,
        "note": null,
        "action": false,
        script: '(values[7]==="No" && values[8]==="Yes")',

        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Number of longitudinal bars being developed along the plane of splitting, n =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": false,
        "note": null,
        "action": false,
        script: '(values[7]==="No" && values[8]==="Yes")',

        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Clear cover to longitudinal reinforcement(Enter # in inches) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": true,
        "note": null,
        "action": false,
        decimalValue: 2,
        decimal: true,
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Center-to-center spacing of the longitudinal bars(Enter # in inches) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": true,
        "note": null,
        "action": false,
        decimalValue: 1,
        decimal: true,

        "row": [

        ],
        "options": [

        ]
    }
]


export const Deformed_Bars_in_Tension_ACI_25_4_3 = [
    {
        "label": "Longitudinal bar size: *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "#3",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": true,
        "row": [
            4,
            6,
            7
        ],
        "options": [
            {
                "optionLabel": "#3"
            },
            {
                "optionLabel": "#4"
            },
            {
                "optionLabel": "#5"
            },
            {
                "optionLabel": "#6"
            },
            {
                "optionLabel": "#7"
            },
            {
                "optionLabel": "#8"
            },
            {
                "optionLabel": "#9"
            },
            {
                "optionLabel": "#10"
            },
            {
                "optionLabel": "#11"
            },
            {
                "optionLabel": "#14"
            },
            {
                "optionLabel": "#18"
            }
        ]
    },
    {
        "label": "fy = *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "60,000 psi",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "60,000 psi"
            },
            {
                "optionLabel": "80,000 psi"
            },
            {
                "optionLabel": "100,000 psi"
            }
        ]
    },
    {
        "label": "f'c (psi) = *",
        "isMandetory": true,
        "lineCount": null,
        "answer": "",
        "type": "editText",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Is lightweight concrete used?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Is Ath ≥ 0.4Ahs or is s ≥ 6db?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": false,
        "note": null,
        "action": false,
        script: '(values[0]==="#3" || values[0]==="#4"|| values[0]==="#5"|| values[0]==="#6"|| values[0]==="#7"|| values[0]==="#8"|| values[0]==="#9"|| values[0]==="#10"|| values[0]==="#11")',
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Are the bars epoxy-coated or zinc and epoxy dual-coated?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
       // script: '(values[0]==="#14"|| values[0]==="#18")',
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Is the hook terminated inside a column core?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": false,
        "note": null,
        "action": false,
        script: '(values[0]==="#3" || values[0]==="#4"|| values[0]==="#5"|| values[0]==="#6"|| values[0]==="#7"|| values[0]==="#8"|| values[0]==="#9"|| values[0]==="#10"|| values[0]==="#11")',
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Side cover normal to plane of hook (in.) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": false,
        "note": null,
        "action": false,
        decimal: true,
        decimalValue: 1,
        script: '(values[0]==="#3" || values[0]==="#4"|| values[0]==="#5"|| values[0]==="#6"|| values[0]==="#7"|| values[0]==="#8"|| values[0]==="#9"|| values[0]==="#10"|| values[0]==="#11")',

        "row": [

        ],
        "options": [

        ]
    }
]

export const Deformed_Bars_in_Tension_ACI_25_4_4 = [
    {
        "label": "Longitudinal bar size: *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "#3",
        "type": "radio",
        "visible": true,
        "action": true,
        "note": null,
        "row": [
            4,
            6,
            7
        ],
        "options": [
            {
                "optionLabel": "#3"
            },
            {
                "optionLabel": "#4"
            },
            {
                "optionLabel": "#5"
            },
            {
                "optionLabel": "#6"
            },
            {
                "optionLabel": "#7"
            },
            {
                "optionLabel": "#8"
            },
            {
                "optionLabel": "#9"
            },
            {
                "optionLabel": "#10"
            },
            {
                "optionLabel": "#11"
            }
        ]
    },
    {
        "label": "fy = *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "60,000 psi",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "60,000 psi"
            },
            {
                "optionLabel": "80,000 psi"
            },
            {
                "optionLabel": "100,000 psi"
            }
        ]
    },
    {
        "label": "f'c (psi) = *",
        "isMandetory": true,
        "lineCount": null,
        "answer": "",
        "type": "editText",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Is lightweight concrete used?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Is Att ≥ 0.3Ahs or is s ≥ 6db?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Are the bars epoxy-coated or zinc and epoxy dual-coated?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Is the headed bar terminated inside a column core?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Side cover to bar (in.) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": true,
        "note": null,
        "action": false,
        decimalValue: 1,
        decimal: true,
        "row": [

        ],
        "options": [

        ]
    }
]

export const Deformed_Bars_in_Tension_ACI_25_4_9 = [
    {
        "label": "Longitudinal bar size: *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "#3",
        "type": "radio",
        "visible": true,
        conditionalNote: true,
        conditionalScript: '(values[0]==="#14" || values[0]==="#18")',
        conditionalScript2: '(values[0]!=="#14" && values[0]!=="#18")',
        condition1: "Note: Compression lap splices are not permitted to be used for #14 and #18 bars except as permitted in ACI 25.5.5.3 (ACI 25.5.5.2)",
        condition2: "",
        "note": "",
        "action": true,
        "row": [
            1
        ],
        "options": [
            {
                "optionLabel": "#3"
            },
            {
                "optionLabel": "#4"
            },
            {
                "optionLabel": "#5"
            },
            {
                "optionLabel": "#6"
            },
            {
                "optionLabel": "#7"
            },
            {
                "optionLabel": "#8"
            },
            {
                "optionLabel": "#9"
            },
            {
                "optionLabel": "#10"
            },
            {
                "optionLabel": "#11"
            },
            {
                "optionLabel": "#14"
            },
            {
                "optionLabel": "#18"
            }
        ]
    },
    {
        "label": "fy = *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "60,000 psi",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "60,000 psi"
            },
            {
                "optionLabel": "80,000 psi"
            },
            {
                "optionLabel": "100,000 psi"
            }
        ]
    },
    {
        "label": "f'c (psi) = *",
        "isMandetory": true,
        "lineCount": null,
        "answer": "",
        "type": "editText",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Is lightweight concrete used?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Is the reinforcement enclosed within a spiral?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Is the reinforcement enclosed within a circular continuously wound tie with db ≥ 1/4 in. and a pitch ≤ 4 in.?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Is the reinforcement enclosed within #4 ties in accordance with ACI 25.7.2 that are spaced ≤ 4 in. on center?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Is the reinforcement enclosed within hoops in accordance with ACI 25.7.4 that are spaced ≤ 4 in. on center?",
        "isMandetory": false,
        "lineCount": null,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Tension lap splice length ℓst (Enter # in inches)=",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": true,
        "note": "Note: This value is required where fy > 80,000 psi and it can be determined using the applicable section of this module using the same parameters that are input in this section.",
        "action": false,
        decimal: true,
        decimalValue: 1,
        "row": [

        ],
        "options": [

        ]
    }
]


export const Compression_AASHTO_5_10_8_2_2 = [
    {
        "label": "Longitudinal bar size: *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "#3",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "#3"
            },
            {
                "optionLabel": "#4"
            },
            {
                "optionLabel": "#5"
            },
            {
                "optionLabel": "#6"
            },
            {
                "optionLabel": "#7"
            },
            {
                "optionLabel": "#8"
            },
            {
                "optionLabel": "#9"
            },
            {
                "optionLabel": "#10"
            },
            {
                "optionLabel": "#11"
            }
        ]
    },
    {
        "label": "fy (ksi) = *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "60",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "60"
            },
            {
                "optionLabel": "75"
            },
            {
                "optionLabel": "80"
            },
            {
                "optionLabel": "100"
            }
        ]
    },
    {
        "label": "f'c (ksi) = *",
        "isMandetory": true,
        "lineCount": null,
        "answer": "",
        "type": "editText",
        "visible": true,
        "note": null,
        "action": false,
        decimal: true,
        decimalValue: 2,
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Is the reinforcement enclosed within a spiral composed of a bar not less than 0.25 in. in diameter and spaced at not more than a 4.0 in. pitch?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Consider reduction due to excess reinforcement?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": true,
        "row": [
            6,
            7
        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Required As (sq in.) =",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "0.00",
        "type": "editText",
        "visible": false,
        "note": null,
        "action": false,
        decimal: true,
        decimalValue: 2,
        "script": '(values[4]==="Yes")',
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Provided As (sq in.) =",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "1.00",
        "type": "editText",
        "visible": false,
        "note": null,
        "action": false,
        decimal: true,
        decimalValue: 1,
        "script": '(values[4]==="Yes")',
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Are ties provided along the splice length that have an effective area not less than 0.15 percent of the product of the thickness of the compression component times the tie spacing?",
        "isMandetory": false,
        "lineCount": null,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    }
]

export const Hooked_AASHTO_5_10_8_2_4 = [
    {
        "label": "Longitudinal bar size: *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "#3",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "#3"
            },
            {
                "optionLabel": "#4"
            },
            {
                "optionLabel": "#5"
            },
            {
                "optionLabel": "#6"
            },
            {
                "optionLabel": "#7"
            },
            {
                "optionLabel": "#8"
            },
            {
                "optionLabel": "#9"
            },
            {
                "optionLabel": "#10"
            },
            {
                "optionLabel": "#11"
            }
        ]
    },
    {
        "label": "fy (ksi) = *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "60",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "60"
            },
            {
                "optionLabel": "75"
            },
            {
                "optionLabel": "80"
            },
            {
                "optionLabel": "100"
            }
        ]
    },
    {
        "label": "Is lightweight concrete used?",
        "isMandetory": false,
        "lineCount": null,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": true,
        "row": [
            3,
            4,
            5,
            6
        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "f'c (ksi) =*",
        "isMandetory": true,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": true,
        decimal: true,
        decimalValue: 1,
        "note": "",
        "action": false,
        conditionalNote: true,
        conditionalScript: '(values[2]==="No" && values[3] && values[3]>15)',
        conditionalScript2: '(values[2]==="Yes" && values[3] && values[3]>10)',
        condition1: "Concrete strength is limited to 15.0 ksi",
        condition2: "Concrete strength is limited to 10.0 ksi",
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Is fct specified?",
        "isMandetory": false,
        "lineCount": null,
        "answer": "No",
        "type": "radio",
        "visible": false,
        "note": null,
        "action": true,
        script: '(values[2]==="Yes")',
        "row": [
            5
        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "fct (ksf) =",
        "isMandetory": true,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        decimal: true,
        decimalValue: 3,
        "visible": false,
        "note": null,
        "action": false,
        script: '(values[2]==="Yes" && values[4]==="Yes")',

        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "wc (kcf) =",
        "isMandetory": true,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": false,
        "note": null,
        decimal: true,
        decimalValue: 3,
        "action": false,
        script: '(values[2]==="Yes")',

        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Is the side cover normal to the plane of the hook not less than 2.5 in., and for 90-deg hooks, is the cover on the bar extension beyond the hook not less than 2.0 in.?",
        "isMandetory": false,
        "lineCount": null,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Are the confinement requirements for 90-deg or 180-deg hooks in the fourth and fifth bullet items, respectively, in Article 5.10.8.2.4b satisfied?",
        "isMandetory": false,
        "lineCount": null,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Are the bars epoxy-coated ?",
        "isMandetory": false,
        "lineCount": null,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Consider reduction due to excess reinforcement?",
        "isMandetory": false,
        "lineCount": null,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": true,
        "row": [
            11,
            12
        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Required As (sq in.) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": false,
        "note": null,
        "action": false,
        decimal: true,
        decimalValue: 2,
        script: '(values[10]==="Yes")',
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Provided As (sq in.) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "1.00",
        "type": "editText",
        "visible": false,
        "note": null,
        "action": false,
        decimal: true,
        decimalValue: 1,
        script: '(values[10]==="Yes")',

        "row": [

        ],
        "options": [

        ]
    }
]

export const Tension_AASHTO_5_10_8_2_1 = [
    {
        "label": "Longitudinal bar size: *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "#3",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "#3"
            },
            {
                "optionLabel": "#4"
            },
            {
                "optionLabel": "#5"
            },
            {
                "optionLabel": "#6"
            },
            {
                "optionLabel": "#7"
            },
            {
                "optionLabel": "#8"
            },
            {
                "optionLabel": "#9"
            },
            {
                "optionLabel": "#10"
            },
            {
                "optionLabel": "#11"
            }
        ]
    },
    {
        "label": "fy (ksi) = *",
        "isMandetory": true,
        "lineCount": 2,
        "answer": "60",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "60"
            },
            {
                "optionLabel": "75"
            },
            {
                "optionLabel": "80"
            },
            {
                "optionLabel": "100"
            }
        ]
    },
    {
        "label": "Is lightweight concrete used?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": true,
        "row": [
            3,
            4,
            5,
            6,
            8
        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "f'c (ksi) = *",
        "isMandetory": true,
        "lineCount": null,
        "answer": "",
        "type": "editText",
        "visible": true,
        "note": null,
        decimalValue: 1,
        decimal: true,
        "action": false,
        conditionalNote: true,
        conditionalScript: '(values[2]==="No" && values[3] && values[3]>15)',
        conditionalScript2: '(values[2]==="Yes" && values[3] && values[3]>10)',
        condition1: "Concrete strength is limited to 15.0 ksi",
        condition2: "Concrete strength is limited to 10.0 ksi",
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Is fct specified?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": false,
        "note": null,
        "action": true,
        script: '(values[2]==="Yes")',
        "row": [
            5
        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "fct (ksi) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": false,
        "note": null,
        decimal: true,
        "action": false,
        decimalValue: 3,
        script: '(values[2]==="Yes" && values[4]==="Yes")',
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "wc (kcf) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": false,
        "note": null,
        decimalValue: 3,
        decimal: true,
        "action": false,
        script: '(values[2]==="Yes")',
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Are the bars being developed horizontal?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": true,
        "row": [
            8
        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Is more than 12 in. of fresh concrete cast below the horizontal reinforcement?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": false,
        "note": null,
        "action": false,
        script: '(values[7]==="Yes")',
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Are the bars epoxy-coated?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": false,
        "row": [

        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Assume ktr = 0?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": true,
        "row": [
            13,
            14,
            15
        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Is transverse reinforcement provided?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": true,
        "row": [
            12,
            13,
            14,
            15,
            16
        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Transverse bar size:",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "#3",
        "type": "radio",
        "visible": false,
        "note": null,
        "action": false,
        script: '(values[11]==="Yes")',

        "row": [

        ],
        "options": [
            {
                "optionLabel": "#3"
            },
            {
                "optionLabel": "#4"
            },
            {
                "optionLabel": "#5"
            },
            {
                "optionLabel": "#6"
            },
            {
                "optionLabel": "#7"
            },
            {
                "optionLabel": "#8"
            }
        ]
    },
    {
        "label": "Total area of transverse reinforcement Atr within spacing s (sq in.) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": false,
        "note": null,
        decimalValue: 2,
        decimal: true,
        "action": false,
        script: '(values[10]==="No" && values[11]==="Yes")',

        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Spacing of transverse reinforcement, s (in.)=",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": false,
        "note": null,
        "action": false,
        decimal: true,
        decimalValue:1,
        script: '(values[10]==="No" && values[11]==="Yes")',

        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Number of longitudinal bars being developed along the plane of splitting, n =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0",
        "type": "editText",
        "visible": false,
        "note": null,
        "action": false,
        script: '(values[10]==="No" && values[11]==="Yes")',

        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Clear cover to longitudinal reinforcement (in.) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": true,
        "note": null,
        "action": false,
        decimal: true,
        decimalValue:2,
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Center-to-center spacing of the longitudinal bars (in.) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": true,
        "note": null,
        "action": false,
        decimal: true,
        decimalValue:1,
        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Consider reduction due to excess reinforcement?",
        "isMandetory": false,
        "lineCount": 1,
        "answer": "No",
        "type": "radio",
        "visible": true,
        "note": null,
        "action": true,
        "row": [
            19,
            20
        ],
        "options": [
            {
                "optionLabel": "Yes"
            },
            {
                "optionLabel": "No"
            }
        ]
    },
    {
        "label": "Required As (sq in.) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "0.00",
        "type": "editText",
        "visible": false,
        "note": null,
        "action": false,
        decimal: true,
        decimalValue:2,
        script: '(values[18]==="Yes")',

        "row": [

        ],
        "options": [

        ]
    },
    {
        "label": "Provided As (sq in.) =",
        "isMandetory": false,
        "lineCount": null,
        "answer": "1.00",
        "type": "editText",
        "visible": false,
        "note": null,
        "action": false,
        decimal: true,
        decimalValue:1,
        script: '(values[18]==="Yes")',

        "row": [

        ],
        "options": [

        ]
    }
]

//             paymentInfo.put("name1", getName1());
//             paymentInfo.put("name2", getName2());
//             paymentInfo.put("addr1", getAddr1());
//             paymentInfo.put("addr2", getAddr2());
//             paymentInfo.put("city", getCity());
//             paymentInfo.put("state", getState());
//             paymentInfo.put("zip", getZip());
//             paymentInfo.put("cardNumber", getCardNumber());
//             paymentInfo.put("expMonth", String.format("%02d", Integer.parseInt(getExpMonth())));
//             paymentInfo.put("expYear", getExpYear());
//             paymentInfo.put("cvv2", getCVV2());
//             paymentInfo.put("amount", getAmount());


    //private static String accountID = "100639859022"; // Gateway Account LIVe ID
    // private static String secretKey = "U4ES9BSI88XHMJ.4VX93EANQLYEXMR04"; // Gateway Secret LIVE Key
    // private static String transactionMode = "LIVE"; // Can be either TEST or LIVE


    //private static String accountID = "100705274644"; // Gateway TEST ID
    //private static String secretKey = "6DTUYXSZFDFICK9LLESXBCXOOQSJX.JW"; // Gateway TESt Key
    //private static String transactionMode = "TEST"; // Can be either TEST or LIVE

    //private static String transactionType = "SALE"; // Can be either SALE or AUTH

