import React from "react";

//import SignIn from './screens/SignIn';
import { MainPage } from './screens/MainScreen';

export default function App() {
  return (
    <MainPage />   
  );
}

