import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import * as routing from '../constants/routing';
import Login from '../screens/Login'
import ForgotPassword from '../screens/ForgotPassword'
export const UnAuthenticatedUserPage = () => {
    return (
        <Router>
            <Switch>
                <Route path={routing.ROUTING_FORGOT_PASSWORD} component={ForgotPassword}  />
                <Route component={Login} />
            </Switch>
        </Router>
    );
};

