export const ROUTING_LOGIN = "/login";
export const ROUTING_FORGOT_PASSWORD = '/forgotPassword';
export const ROUTING_HOME_DASHBOARD = '/home';
export const ROUTING_ACI = '/aci/:id';
export const ROUTING_REPORT_ACI = '/aci/:id/report';
export const ROUTING_AASHTO = '/aashto/:id';
export const ROUTING_RESULT = '/result/output/:id';
export const ROUTING_PAYMENT = '/payment/:id';
export const ROUTING_STATUS_PAYMENT = '/payment/status';
export const ROUTING_AASHTO_PAYMENT = '/payment/aashto/success';

