import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { authLoginSucess } from '../actions/auth'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import firebase from "firebase/app";
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import ls from 'local-storage'
import ProgressBar from '../common/ProgressBar'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(11),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#367DB5"
  },
  topAppBar: {
    top: 0,
    bottom: 'auto',
    backgroundColor: 'white',
    color: '#7E7E7E',
    justifyContent: 'center'

  },
  title: {
    flexGrow: 1,
    color: '#7E7E7E'
  },
  bottomAppBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: '#586B72'
  },
  titleBottom: {
    flexGrow: 1,
    color: '#EBF0F3'
  },
  img: {
    width: 200,
    resizeMode: 'contain',
    alignSelf: 'center'

  },
  qrImg1: {
    width: 100,
    resizeMode: 'contain',
    alignSelf: 'center',
    marginRight: 20,

  },
  qrImg2: {
    width: 100,
    resizeMode: 'contain',
    alignSelf: 'center',
    marginLeft: 20,

  },
  txt1: {
    width: 100,
    alignSelf: 'center',
    marginRight: 20,
   textAlign:'center'

  },
  txt2: {
    width: 100,
    alignSelf: 'center',
    marginLeft: 20,
   textAlign:'center'

  },
  description1: {
    marginTop: 10,
    color: '#7E7E7E',
    fontSize: 12
  },
  description2: {
    marginTop: 20,
    color: '#7E7E7E',
    fontSize: 12
  },
  qrView: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const LoginScreen = (props) => {
  const { authLoginSucess } = props
  const classes = useStyles();
  //const instance = firebase.initializeApp(config);
  const [loading, setLoading] = useState(null)

  const settingLoader = () => {
    setLoading(!loading)
  };
  return (
    // <FirebaseAuthProvider {...config} firebase={firebase}>

    <Container component="main" maxWidth="xs">
      {/* <CssBaseline /> */}
      <AppBar position="fixed" color="primary" className={classes.topAppBar}>
        <Toolbar style={{ justifyContent: "center" }}>
          <img
            className={classes.img}
            src="https://i.ibb.co/r7grPXS/logo-with-text.png"
            alt="image"
          />

        </Toolbar>
        <div style={{ backgroundColor: '#F4BC33', height: 5 }}></div>
      </AppBar>
      <div className={classes.paper}>

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <SignInForm {...props}
          settingLoader={settingLoader}
        />
        {loading && <ProgressBar />}
        <div className={classes.description1}>The purchase of a Development Lengths premium module in CRSI’s Rebar Reference mobile app is required to access the desktop version. Purchasers, please use your mobile app credentials to sign into the desktop version.</div>
        <div className={classes.description2}>To download the Rebar Reference mobile app, please visit the appropriate app store below. After signing in on the mobile app, you will have the ability to purchase either the ACI and/or AASHTO Development Lengths premium modules. Either module will give you access to the desktop version. </div>

        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="space-evenly"
          style={{ marginTop: 20, justifyContent: 'center', justifyItems: 'center' }}
        >

          <img
            className={classes.qrImg1}
            src="https://i.ibb.co/wJ1tw4x/android-app.png"
            alt="image"
          />
          <img
            className={classes.qrImg2}

            // className={classes.qrImg}
            src="https://i.ibb.co/sgCBnDQ/ios-app.png"
            alt="image"
          />
        </Grid>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="space-evenly"
          style={{ marginTop: 20, justifyContent: 'center', justifyItems: 'center' }}
        >

          <span
            className={classes.txt1}
          >
            Play Store
         </span>
          <span
            className={classes.txt2}
          >
            App Store
         </span>
          {/* <img
            className={classes.qrImg2}

            // className={classes.qrImg}
            src="https://i.ibb.co/sgCBnDQ/ios-app.png"
            alt="image"
          /> */}
        </Grid>
      </div>

      <AppBar position="fixed" color="primary" className={classes.bottomAppBar}>
        <div style={{ backgroundColor: '#F4BC33', height: 5 }}></div>
        <Toolbar>
          <Typography variant="h8" className={classes.titleBottom}>
            @2021 Sitemap | Privacy Policy | Design + Hosted by Appsomatic
          </Typography>
        </Toolbar>
      </AppBar>
    </Container>
    // </FirebaseAuthProvider>

  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  // isLoading: state.global.isLoading
});


const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      //  doLogout: doLogoutAPI,
      authLoginSucess
    }, dispatch),
});
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};
class SignInFormBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: null,
    };
  }

  onSubmit = event => {
    this.props.settingLoader()
    const { email, password } = this.state;
    console.log("email  : " + email)
    firebase.auth().signInWithEmailAndPassword(email.trim(), password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        console.log("user : ", JSON.stringify(user))
        ls('user', JSON.stringify(JSON.stringify(user)));

        var userId = user.uid;
        firebase.database().ref('/users/' + userId).once('value').then((snapshot) => {
          console.log("snapshot.val() , ", snapshot.val())
          ls('moduleData', JSON.stringify(snapshot.val()));
          this.props.settingLoader()
          this.props.authLoginSucess()
        });
        // this.props.authLoginSucess()
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        alert(errorCode + errorMessage)
        this.props.settingLoader()
      });

    event.preventDefault();
  };

  onChange = event => {
    console.log("event.target.name  : ", event.target.name, "  event.target.value   ", event.target.value)
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form
        //className={classes.form} 
        noValidate
        onSubmit={this.onSubmit}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={this.onChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          borderColor="green"
          autoComplete="current-password"
          style={{ fontFamily: 'nunito', color: 'red', borderColor: 'green' }}
          onChange={this.onChange}


        />
        {/* <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        /> */}
        <Button
          //onClick={() => authLoginSucess()}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        //className={classes.submit}
        >
          Sign In
        </Button>
        <Grid container style={{ marginTop: 10 }}>
          <Grid item xs>
            <Link href="/forgotPassword" variant="body2">
              Forgot password?
            </Link>
          </Grid>
        </Grid>
        <Typography style={{ color: '#3C7CA0'}} variant="h9" align="center">
          {"The purchase of a Development Lengths premium module in CRSI’s Rebar Reference mobile app is required to access the desktop version. Purchasers, please use your mobile app credentials to sign into the desktop version."}
        </Typography>

      </form>

    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);


export { SignInForm };


const Login = connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
export default Login;


