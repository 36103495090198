import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { ROUTING_FORGOT_PASSWORD } from '../constants/routing'
import { authLoginSucess } from '../actions/auth'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import firebase from "firebase/app";
import ProgressBar from '../common/ProgressBar'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(11),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#367DB5"
  },
  topAppBar: {
    top: 0,
    bottom: 'auto',
    backgroundColor: 'white'
  },
  title: {
    flexGrow: 1,
    color: '#7E7E7E'
  },
  bottomAppBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: '#586B72'
  },
  titleBottom: {
    flexGrow: 1,
    color: '#EBF0F3'
  },
  img: {
    width: 200,
    resizeMode: 'contain',
    alignSelf: 'center'

  },
}));

const ForgotPasswordScreen = (props) => {
  //const { authLoginSucess } = props
  const classes = useStyles();
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const resetpassword = async () => {
    //alert(email)
    if (!email) {
      alert("Please enter Email ID")
      return;
    }
    setLoading(true)
    await firebase.auth().sendPasswordResetEmail(email)
    setLoading(true)
    setEmail("")
    alert("Email Sent Successfully")
  }
  return (
    <Container component="main" maxWidth="xs">
      {/* <CssBaseline /> */}
      <AppBar position="fixed" color="primary" className={classes.topAppBar}>
        <Toolbar style={{ justifyContent: 'center' }}>
          <img
            className={classes.img}
            src="https://i.ibb.co/r7grPXS/logo-with-text.png"
            alt="image"
          />

        </Toolbar>
        <div style={{ backgroundColor: '#F4BC33', height: 5 }}></div>
      </AppBar>
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={email}
            autoComplete="email"
            onChange={e => setEmail(e.target.value)}
            autoFocus
          />

          <Button
            onClick={() => resetpassword()}
            // type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>

        </form>
        {loading && <ProgressBar />}

      </div>
      <AppBar position="fixed" color="primary" className={classes.bottomAppBar}>
        <div style={{ backgroundColor: '#F4BC33', height: 5 }}></div>
        <Toolbar>
          <Typography variant="h8" className={classes.titleBottom}>
            @2021 Sitemap | Privacy Policy | Design + Hosted by Appsomatic
          </Typography>
        </Toolbar>
      </AppBar>
    </Container>
  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  // isLoading: state.global.isLoading
});


const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      //  doLogout: doLogoutAPI,
      //  authLoginSucess
    }, dispatch),
});
const ForgotPassword = connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScreen);
export default ForgotPassword;
