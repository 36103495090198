export const rebarData = {
    "#3": 0.375,
    "#4": 0.5,
    "#5": 0.625,
    "#6": 0.75,
    "#7": 0.875,
    "#8": 1,
    "#9": 1.128,
    "#10": 1.27,
    "#11": 1.41,
    "#14": 1.693,
    "#18": 2.257,
    "No": 2.257,
}

