import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { authLogout } from '../actions/auth'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from 'react-router-dom';
import ls from 'local-storage'
const breakpoint = 620;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#367DB5'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: '#7E7E7E'
  },
  titleBottom: {
    flexGrow: 1,
    color: '#EBF0F3'
  },
  paper: {
    //marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center'

    },
    // backgroundColor:'green'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  topAppBar: {
    top: 0,
    bottom: 'auto',
    backgroundColor: 'white'
  },
  bottomAppBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: '#586B72'
  },
  card: {
    [theme.breakpoints.only('sm')]: {
      maxWidth: 345,
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: 345,
    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: 345,
    },
    [theme.breakpoints.only('xl')]: {
      maxWidth: 345,
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: 345,
    },

    backgroundColor: 'white',
    height: '100%',
    alignSelf: 'center',
    borderColor: '#F4BC33',
    borderWidth: 5,
    justifyContent: 'center'
  },

  button: {
    margin: theme.spacing(1),
  },
  action: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  img: {
    width: 200,
    resizeMode: 'contain',
    alignSelf: 'center',
    display: 'flex'

  },
  grid: {
    height: 160,
    [theme.breakpoints.only('sm')]: {
      width: '70%',
      marginTop: 80
    },
    [theme.breakpoints.only('md')]: {
      width: '35%',
      marginTop: 0
    },
    [theme.breakpoints.only('lg')]: {
      width: '35%',
      marginTop: 0
    },
    [theme.breakpoints.only('xl')]: {
      width: '35%',
      marginTop: 0
    },
    [theme.breakpoints.only('xs')]: {
      width: '70%',
      marginTop: 80
    },

  }
}));

const HomeScreen = (props) => {
  const { authLogout } = props
  const classes = useStyles();
  const history = useHistory()
  const [aciEnable, setAciEnable] = useState(false)
  const [aashtoEnable, setAashtoEnable] = useState(false)
  const [paymentState, setPaymentState] = useState(null)
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    //alert(window.location.protocol + '//' + window.location.host)
    const moduleData = ls.get('moduleData');
    console.log("moduleData  :  ", moduleData)
    if (moduleData) {
      const data = JSON.parse(moduleData)
      if (data) {
        data.subscriptionID318 && setAciEnable(true)
        data.subscriptionIDaastho && setAashtoEnable(true)

      }
    }
  }, [])
  React.useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    /* passing an empty array as the dependencies of the effect will cause this
       effect to only run when the component mounts, and not each time it updates.
       We only want the listener to be added once */

  }, []);
  const enableAciPayment = () => {
    ls('module', "aci");
    setPaymentState({ type: "aci" })
  }
  const enableAashtoPayment = () => {
    ls('module', "aashto");
    setPaymentState({ type: "aashto" })
  }

  return (
    <Container
      maxWidth={false}
      style={{
        backgroundColor: '#367DB5', display: 'flex', justifyContent: 'center', alignItems: 'center'
      }}
    >

      <div
        className={classes.paper}>

        <AppBar position="fixed" color="primary" className={classes.topAppBar}>
          <Toolbar>

            <div style={{ flex: .93, display: 'flex', justifyContent: 'center' }}>
              <img
                className={classes.img}
                src="https://i.ibb.co/r7grPXS/logo-with-text.png"
                alt="image"
              />
            </div>
            <div style={{ flex: .07, justifyContent: "flex-end", alignItems: 'flex-end' }}>
              <Button
                onClick={() => {
                  ls('user', null);
                  authLogout()
                }}
                style={{ backgroundColor: "#F8BF33", color: 'white', alignSelf: 'flex-end', }}>Logout</Button>
            </div>

          </Toolbar>
          <div style={{ backgroundColor: '#F4BC33', height: 5 }}></div>
        </AppBar>

        <Grid container spacing={3}
          className={classes.grid}
        >
          <Grid item xs={12} lg={6} xl={6} sm={6}>
            <Card
              onClick={() => {
                aciEnable ? history.push(`/aci/25_4_2_3`) :
                  enableAciPayment()
              }}
              className={classes.card} variant="outlined">
              <CardContent>
                <div style={{
                  flexDirection: 'row', display: 'flex',
                  justifyContent: 'center', alignItems: 'center',
                }}>
                  <span style={{ color: '#3C7CA0', fontSize: 25, alignSelf: 'center' }}>
                    &#120001;
                </span>
                  <span style={{ color: '#3C7CA0', fontSize: 18 }}>
                    <sub>&#119889;</sub>
                  </span>
                  <span style={{ backgroundColor: '#3C7CA0', color: 'white', padding: 5, marginLeft: 10, marginTop: -15 }}>ACI 318</span>
                </div>

                <Typography style={{ color: '#3C7CA0' }} variant="h5" align="center">
                  {"Development Lengths"}
                </Typography>
              </CardContent>

            </Card>
          </Grid>
          <Grid item xs={12} lg={6} xl={6} sm={6}>
            <Card
              onClick={() => {
                aashtoEnable ?
                  history.push(`/aashto/5_10_8_2_1`) : enableAashtoPayment()
              }}
              className={classes.card} variant="outlined">
              <CardContent>
                <div style={{
                  flexDirection: 'row', display: 'flex',
                  justifyContent: 'center', alignItems: 'center',
                }}>
                  <span style={{ color: '#3C7CA0', fontSize: 25, alignSelf: 'center' }}>
                    &#120001;
                </span>
                  <span style={{ color: '#3C7CA0', fontSize: 18 }}>
                    <sub>&#119889;</sub>
                  </span>
                  <span style={{ backgroundColor: '#3C7CA0', color: 'white', padding: 5, marginLeft: 10, marginTop: -15 }}>AASHTO</span>
                </div>

                <Typography style={{ color: '#3C7CA0' }} variant="h5" align="center">
                  {"Development Lengths"}
                </Typography>
              </CardContent>

            </Card>
          </Grid>
        </Grid>
        <AppBar position="fixed" color="primary" className={classes.bottomAppBar}>
          <div style={{ backgroundColor: '#F4BC33', height: 5 }}></div>
          <Toolbar>
            <Typography variant="h8" className={classes.titleBottom}>
              @2021 Sitemap | Privacy Policy | Design + Hosted by Appsomatic
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      {paymentState && <div style={{
        borderRadius: 10, borderWidth: 5, borderColor: 'yellow', position: 'absolute', height: '70%', width: width < breakpoint ? '70%' : '20%',
        background: 'white', display: 'flex', alignSelf: 'center', flexDirection: 'column', padding: 10
      }}>
        <Card style={{
          width: 150,
          backgroundColor: 'white',
          height: 150,
          alignSelf: 'center',
          borderColor: '#F4BC33',
          borderWidth: 5,
          justifyContent: 'center',
          marginTop: 10,

        }} variant="outlined">
          <CardContent style={{ flexDirection: 'column' }}>
            <div style={{
              flexDirection: 'row', display: 'flex',
              justifyContent: 'center', alignItems: 'center',
            }}>
              <span style={{ color: '#3C7CA0', fontSize: 25, alignSelf: 'center' }}>
                &#120001;
                </span>
              <span style={{ color: '#3C7CA0', fontSize: 18 }}>
                &#119889;
               </span>
              <span style={{ backgroundColor: '#3C7CA0', color: 'white', padding: 5, marginLeft: 10, marginTop: -15 }}>{paymentState?.type === 'aci' ? "ACI 318" : "AASHTO"}</span>
            </div>

            <Typography style={{ color: '#3C7CA0' }} variant="h6" align="center">
              {"Development"}
            </Typography>
            <Typography style={{ color: '#3C7CA0' }} variant="h6" align="center">
              {"Lengths"}
            </Typography>
          </CardContent>
        </Card>
        <div style={{ color: '#3C7CA0', textAlign: 'center', }}><h3>{`Development Lengths module ${paymentState.type === 'aci' ? "ACI" : "AASHTO"} version`}</h3></div>
        <div style={{ color: '#3C7CA0', textAlign: 'center', marginTop: -20 }}><h3>(One Time Purchase)</h3></div>
        <div style={{ color: '#3C7CA0', textAlign: 'center', marginTop: -20 }}><h5>Calculate the development lengths and map splice length for steel reinforcing bars of any grade including deformed bars in tension in this easy to use tool.</h5></div>
        <div style={{ color: '#3C7CA0', textAlign: 'center', marginTop: -20 }}><h3>Please Purchase from Mobile application</h3></div>
        <Button
          onClick={() => setPaymentState(false)}
          variant="contained" style={{ backgroundColor: '#3C7CA0', height: 50, }} color="primary" className={classes.button}
        >Ok</Button>
        {/* <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center', marginTop: -20 }}>
          <Button
            onClick={() => setPaymentState(false)}
            variant="contained" style={{ backgroundColor: '#3C7CA0', height: 50, }} color="primary" className={classes.button}
          >No Thanks</Button>
          <Button
            href="https://secure.bluepay.com/interfaces/shpf?SHPF_FORM_ID=mobilecap01&SHPF_ACCOUNT_ID=100705274644&SHPF_TPS_DEF=SHPF%5FFORM%5FID%20SHPF%5FACCOUNT%5FID%20DBA%20TAMPER%5FPROOF%5FSEAL%20AMEX%5FIMAGE%20DISCOVER%5FIMAGE%20TPS%5FDEF%20TPS%5FHASH%5FTYPE%20SHPF%5FTPS%5FDEF%20CUSTOM%5FHTML%20SHPF%5FTPS%5FHASH%5FTYPE%20CAPTCHA%5FREQUIRED%20AMOUNT&SHPF_TPS_HASH_TYPE=HMAC%5FSHA512&SHPF_TPS=2cb72a3d05e2314b30af2613e77d1b4dbe3dc0f8e32fd82d50d90ff8eecfa27f502927b42c0c36f9de2e00330e65a262649c0700de1115cd52492da056bfce47&MODE=TEST&TRANSACTION_TYPE=SALE&DBA=Gaurav&AMOUNT=4%2E99&TAMPER_PROOF_SEAL=fc737c9754f0b336125edc91c0050a9f5437f278d49ec5f0c2e7de827ac7335163197dc7d43d978f8fda28593e309c34283e22eace6ff7e24216b4d93efc377d&CUSTOM_ID=&CUSTOM_ID2=&REBILLING=0&REB_CYCLES=&REB_AMOUNT=&REB_EXPR=&REB_FIRST_DATE=&AMEX_IMAGE=amex%2Egif&DISCOVER_IMAGE=discvr%2Egif&REDIRECT_URL=https%3A%2F%2Fsecure%2Ebluepay%2Ecom%2Finterfaces%2Fshpf%3FSHPF%5FFORM%5FID%3Dmobileresult01%26SHPF%5FACCOUNT%5FID%3D100705274644%26SHPF%5FTPS%5FDEF%3DSHPF%255FACCOUNT%255FID%2520SHPF%255FFORM%255FID%2520RETURN%255FURL%2520DBA%2520AMEX%255FIMAGE%2520DISCOVER%255FIMAGE%2520SHPF%255FTPS%255FDEF%2520SHPF%255FTPS%255FHASH%255FTYPE%26SHPF%5FTPS%5FHASH%5FTYPE%3DHMAC%255FSHA512%26SHPF%5FTPS%3D373f80f30a5d64901579447924e10edb0dadc7871b3bdad24e6f1366866065f488f38b373be50a203687fda10a141cb72b6e2e9706f1bae3fe84086cf59509a9%26RETURN%5FURL%3Dhttps%253A%252F%252Fgoogle%252Ecom%26DBA%3DGaurav%26AMEX%5FIMAGE%3Damex%252Egif%26DISCOVER%5FIMAGE%3Ddiscvr%252Egif&TPS_DEF=MERCHANT%20APPROVED%5FURL%20DECLINED%5FURL%20MISSING%5FURL%20MODE%20TRANSACTION%5FTYPE%20TPS%5FDEF%20TPS%5FHASH%5FTYPE%20CAPTCHA%5FREQUIRED%20AMOUNT&TPS_HASH_TYPE=HMAC%5FSHA512&CUSTOM_HTML=&CARD_TYPES=vi%2Dmc%2Ddi%2Dam&CAPTCHA_REQUIRED=1"
            // href="https://secure.bluepay.com/interfaces/shpf?SHPF_FORM_ID=mobileform01D&SHPF_ACCOUNT_ID=100705274644&SHPF_TPS_DEF=SHPF%5FFORM%5FID%20SHPF%5FACCOUNT%5FID%20DBA%20TAMPER%5FPROOF%5FSEAL%20AMEX%5FIMAGE%20DISCOVER%5FIMAGE%20TPS%5FDEF%20TPS%5FHASH%5FTYPE%20SHPF%5FTPS%5FDEF%20CUSTOM%5FHTML%20SHPF%5FTPS%5FHASH%5FTYPE&SHPF_TPS_HASH_TYPE=HMAC%5FSHA512&SHPF_TPS=5596b4c21e21cd5474312ed2bb9519b28e2e402cdf08219f569d2e52402c66381b83dc1600dbaa3c822b0018efa01bcfb9d15cee60e516468cc6b24e73a4c77a&MODE=TEST&TRANSACTION_TYPE=SALE&DBA=CRSI&AMOUNT=4.99&TAMPER_PROOF_SEAL=cee587c1ce63acbf2561a9efae2bfd0c2cba98b382a1471ab0f858032ac4c22db37bd37fe9bb1ff4a09b0a3db119213037ac740d544c4e125113d82e30d59ea4&CUSTOM_ID=&CUSTOM_ID2=&REBILLING=0&REB_CYCLES=&REB_AMOUNT=&REB_EXPR=&REB_FIRST_DATE=&AMEX_IMAGE=amex%2Egif&DISCOVER_IMAGE=discvr%2Egif&REDIRECT_URL=http%3A%2F%2Flocalhost%3A3000%2Fpayment%2Fstatus&TPS_DEF=MERCHANT%20APPROVED%5FURL%20DECLINED%5FURL%20MISSING%5FURL%20MODE%20TRANSACTION%5FTYPE%20TPS%5FDEF%20TPS%5FHASH%5FTYPE&TPS_HASH_TYPE=HMAC%5FSHA512&CUSTOM_HTML=&CARD_TYPES=vi%2Dmc%2Ddi%2Dam"
            variant="contained" style={{ backgroundColor: '#3C7CA0', height: 50, }} color="primary" className={classes.button}
          >Buy Now</Button>
        </div> */}
      </div>}
    </Container>
  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});


const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      //  doLogout: doLogoutAPI,
      authLogout
    }, dispatch),
});
const Home = connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
export default Home;
