import React from 'react';


export const outputLabel = (value) => {
    if (value === 'db =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}><i>{"d"}</i></span ><sub>b</sub>{" ="}</i></span>

    }
    else if (value === 'λrc =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}><i>{"λ"}</i></span ><sub>rc</sub>{" ="}</i></span>

    }
    else if (value === 'ψc =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}><i>{"ψ"}</i></span ><sub>c</sub>{" ="}</i></span>

    }
    else if (value === 'λcf =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"λ"}</span ><sub>cf</sub>{" ="}</i></span>

    }
    else if (value === 'λcw =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"λ"}</span ><sub>cw</sub>{" ="}</i></span>

    }
    else if (value === 'λer =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"λ"}</span ><sub>er</sub>{" ="}</i></span>

    }
    else if (value === 'λrl =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"λ"}</span ><sub>rl</sub>{" ="}</i></span>

    }
    else if (value === 'λrlλcf =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"λ"}</span ><sub>{"rl"}</sub><span style={{ fontSize: 19, color: 'white' }}> {"λ"}</span ><sub>cf</sub>{" ="}</i></span>

    }
    else if (value === 'Cb =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"C"}</span ><sub>b</sub>{" ="}</i></span>

    }
    else if (value === 'ktr =') {
        return <span style={{ fontSize: 14, color: 'white' }}><span style={{ fontSize: 19, color: 'white' }}><i>{"k"}</i></span ><sub>tr</sub>{" ="}</span>

    }
    else if (value === 'ψg =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"ψ"}</span ><sub>g</sub>{" ="}</i></span>

    }
    else if (value === 'ψt =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"ψ"}</span ><sub>t</sub>{" ="}</i></span>

    }
    else if (value === 'ψe =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"ψ"}</span ><sub>e</sub>{" ="}</i></span>

    }
    else if (value === 'ψs =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"ψ"}</span ><sub>s</sub>{" ="}</i></span>

    }
    else if (value === 'ψr =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"ψ"}</span ><sub>r</sub>{" ="}</i></span>

    }
    else if (value === 'ψo =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"ψ"}</span ><sub>o</sub>{" ="}</i></span>

    }
    else if (value === 'ψp =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"ψ"}</span ><sub>p</sub>{" ="}</i></span>

    }
    else if (value === 'Modified tension development length for deformed bars 𝓁d  =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"Modified tension development length for deformed bars 𝓁"}</span ><sub>d</sub>{" ="}</i></span>

    }
    else if (value === 'ψtψe =') {
        return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 19, color: 'white' }}>{"ψ"}</span ><sub>{"t"}</sub><span style={{ fontSize: 19, color: 'white' }}> {"ψ"}</span ><sub>e</sub>{" ="}</i></span>

    }

    else {
        const array = value.split("");
        return <span>{array.map(item => getSpan(item))}</span>
    }
}

export const outputHeaderLabel = (value) => {
    //console.log("value : ", value)

    const array = value.split("");
    //console.log("array : ", array)
    return <span>{array.map(item => getHeaderSpan(item))}</span>

}


const getHeaderSpan = (char) => {
    //console.log("char header :  ", char)
    switch (char) {
        case String.fromCodePoint(0xD835):
            return <span style={{ fontSize: 19, color: '#367DB5' }}>{"𝓁"}</span>
        default:
            return <span style={{ fontSize: 14, color: '#367DB5' }}>{char}</span>
    }
}
const getSpan = (char) => {

    switch (char) {
        case "ψ":
            return <span style={{ fontSize: 19, color: 'white' }}><i>{char}</i></span>
        case "λ":
            return <span style={{ fontSize: 19, color: 'white' }}><i>{char}</i></span>
        case "K":
            return <span style={{ fontSize: 19, color: 'white' }}><i>{char}</i></span>
        case "c":
            return <span style={{ fontSize: 22, color: 'white' }}><i>{char}</i></span>
        case "d":
            return <span style={{ fontSize: 19, color: 'white' }}><i>{char}</i></span>
        case "b":
            return <span style={{ fontSize: 14, color: 'white' }}><i>{char}</i></span>
        case "t":
            return <span style={{ fontSize: 14, color: 'white' }}><i>{char}</i></span>
        case "r":
            return <span style={{ fontSize: 14, color: 'white' }}><i>{char}</i></span>
        default:
            return <span style={{ fontSize: 14, color: 'white' }}><i>{char}</i></span>
    }
}


export const getInputLabel = (value) => {
    // console.log("labels : ", value)
    switch (value) {
        case "Required As (sq in.) =":
            return <span style={{ fontSize: 14, color: 'white' }}>Required <i><span style={{ fontSize: 18 }}>{"A"}</span><sub>s</sub></i> (sq in.) =</span>
        case "Provided As (sq in.) =":
            return <span style={{ fontSize: 14, color: 'white' }}>Provided <i><span style={{ fontSize: 18 }}>{"A"}</span><sub>s</sub></i> (sq in.) =</span>
        case "fy (ksi) = *":
            return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 18 }}>{"f"}</span></i><sub>y</sub> (ksi) = *</span>
        case "Assume ktr = 0?":
            return <span style={{ fontSize: 14, color: 'white' }}>Assume <i><span style={{ fontSize: 18 }}>{"k"}</span><sub>tr</sub></i> = 0 ?</span>
        case "Assume Ktr = 0?":
            return <span style={{ fontSize: 14, color: 'white' }}>Assume <span style={{ fontSize: 18 }}><i>{"K"}</i></span><sub><i>tr</i></sub> = 0 ?</span>
        case "f'c (ksi) = *":
            return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 18 }}>{"f "}</span></i>'<sub>c</sub> (ksi) = *</span>
        case "f'c (ksi) =*":
            return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 18 }}>{"f "}</span></i>'<sub>c</sub> (ksi) = *</span>
        case "fy = *":
            return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 18 }}>{"f "}</span></i><sub>y</sub> = *</span>
        case "f'c (psi) = *":
            return <span style={{ fontSize: 14, color: 'white' }}><i><span style={{ fontSize: 18 }}>{"f "}</span></i>'<sub>c</sub> (psi) = *</span>
        // case "Is Ath ≥ 0.4Ahs or is s ≥ 6db?":
        //     return <span style={{ fontSize: 14, color: 'white' }}>Is <i><span style={{ fontSize: 18 }}>{"A"}</span><sub>th</sub> </i>≥ 0.4<i><span style={{ fontSize: 18 }}>{"A"}</span>hs </i>or is s ≥ 6<i><span style={{ fontSize: 18 }}>{"d"}</span><sub>b</sub> </i>?</span>
        //     break;
        case "Is Att ≥ 0.3Ahs or is s ≥ 6db?":
            return <span style={{ fontSize: 14, color: 'white' }}>Is <i><span style={{ fontSize: 18 }}>{"A"}</span><sub>tt</sub> </i>≥ 0.3<i><span style={{ fontSize: 18 }}>{"A"}</span><sub>hs</sub> </i>or is s ≥ 6<i><span style={{ fontSize: 18 }}>{"d"}</span>b </i>?</span>

        case "Total area of transverse reinforcement Atr within spacing s (sq in) =":
            return <span style={{ fontSize: 14, color: 'white' }}>Total area of transverse reinforcement <span style={{ fontSize: 18 }}><i>{"A"}</i></span><sub><i>tr</i></sub> within spacing <i>s</i> (sq in.) =</span>
        case "Total area of transverse reinforcement Atr within spacing s (sq in.) =":
            return <span style={{ fontSize: 14, color: 'white' }}>Total area of transverse reinforcement <span style={{ fontSize: 18 }}><i>{"A"}</i></span><sub><i>tr</i></sub> within spacing <i>s</i> (sq in.) =</span>

        case "Spacing of transverse reinforcement, s (in.)=":
            return <span style={{ fontSize: 14, color: 'white' }}>Spacing of transverse reinforcement, <i>s</i> (in) =</span>
        case "Spacing of transverse reinforcement, s (in.) =":
            return <span style={{ fontSize: 14, color: 'white' }}>Spacing of transverse reinforcement, <i>s</i> (in) =</span>
        case "Number of longitudinal bars being developed along the plane of splitting, n =":
            return <span style={{ fontSize: 14, color: 'white' }}>Number of longitudinal bars being developed along the plane of splitting, <i>n</i> =</span>
        case "Is Ath ≥ 0.4Ahs or is s ≥ 6db?":
            return <span style={{ fontSize: 14, color: 'white' }}>Is <i>A</i><sub>th</sub> ≥ 0.4<i>A</i><sub>hs</sub> or is s ≥ 6<i>d</i><sub>b</sub> ?</span>
        case "Is fct specified?":
            return <span style={{ fontSize: 14, color: 'white' }}>Is <i style={{ fontSize: 18, color: 'white' }}>f<sub>ct</sub></i> specified?</span>
        case "fct (ksi) =":
            return <span style={{ fontSize: 14, color: 'white' }}><i style={{ fontSize: 18, color: 'white' }}>f<sub>ct</sub></i> (ksi) =</span>
        case "fct (ksf) =":
            return <span style={{ fontSize: 14, color: 'white' }}><i style={{ fontSize: 18, color: 'white' }}>f<sub>ct</sub></i> (ksf) =</span>
        case "wc (kcf) =":
            return <span style={{ fontSize: 14, color: 'white' }}><i style={{ fontSize: 18, color: 'white' }}>w<sub>c</sub></i> (kcf) =</span>
        default:
            return <span style={{ fontSize: 14, color: 'white' }}>{value}</span>
    }
    
}

const contains = (value) => {
    if (value.includes("fy ") || value.includes("f'c ") || value.includes("As (sq in.)") || value.includes("Ktr")) {
        return true;
    }
    return false;
}