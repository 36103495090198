import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import * as routing from '../constants/routing';
import Home from '../screens/Home'
import PermanentDrawerLeft from '../common/PermanentDrawerLeft'
import Aci from '../screens/LengthListing'
import Result from '../screens/Result';
import Payment from '../screens/Payment';
import PaymentStatus from '../screens/PaymentStatus';
import Report from '../screens/Report';


export const AuthenticatedUserPage = () => {
    return (
        <Router>
            <Switch>
                <Route path={routing.ROUTING_ACI} component={PermanentDrawerLeft} exact />
                <Route path={routing.ROUTING_REPORT_ACI} component={Report} exact />
                <Route path={routing.ROUTING_AASHTO} component={PermanentDrawerLeft} exact />
                <Route path={routing.ROUTING_RESULT} component={Result} exact />
                {/* <Route path={routing.ROUTING_PAYMENT} component={Payment} exact /> */}
                <Route path={routing.ROUTING_STATUS_PAYMENT} component={PaymentStatus} exact />
                {/* <Route path={routing.ROUTING_AASHTO_PAYMENT} component={Payment} exact /> */}

                <Route component={Home} />

            </Switch>
        </Router>
    );
};

