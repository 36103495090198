
import React, { useState, useEffect, useRef } from 'react';
import {

    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {
    aciLengths,
    Deformed_Bars_in_Tension_ACI_25_4_2_3,
    Deformed_Bars_in_Tension_ACI_25_4_2_4,
    Deformed_Bars_in_Tension_ACI_25_4_3,
    Deformed_Bars_in_Tension_ACI_25_4_4,
    Deformed_Bars_in_Tension_ACI_25_4_9,
    Hooked_AASHTO_5_10_8_2_4,
    Tension_AASHTO_5_10_8_2_1,
    Compression_AASHTO_5_10_8_2_2,
    aashtoLengths
} from '../data/Data'
import Grid from '@material-ui/core/Grid';

import FormLabel from '@material-ui/core/FormLabel';
//import { TextField, } from 'formik-material-ui';
import TextField from '@material-ui/core/TextField';

import { useHistory } from 'react-router-dom';


import { Formik, Form, } from 'formik';
import { aci25423, aci2543, aashto510821, aashto510822, aashto510824, aci25424, aci2549, aci2544 } from '../formulas/formulas';
import Result from '../screens/Result';
import { getInputLabel } from './commonLabels';
import { Link } from '@material-ui/core';
import ReportComponent from '../screens/ReportComponent';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        backgroundColor: "#367DB5",

    },
    appBar: {

        [theme.breakpoints.only('sm')]: {
            width: '100%',
            backgroundColor: '#367DB5',

        },
        [theme.breakpoints.only('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            backgroundColor: '#367DB5',

        },
        [theme.breakpoints.only('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            backgroundColor: '#367DB5',

        },
        [theme.breakpoints.only('xl')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            backgroundColor: '#367DB5',

        },
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            backgroundColor: '#FFFFFF',

        },
        marginLeft: drawerWidth,

    },
    img: {
        width: drawerWidth - 100,
        resizeMode: 'contain',
        alignSelf: 'center',
        height: 40

    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    openDrawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {

        width: drawerWidth,
        backgroundColor: '#FFFFFF'

    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        //xs' | 'sm' | 'md' | 'lg' | 'xl
        // flexGrow: 1,
        backgroundColor: "#367DB5",

        [theme.breakpoints.only('sm')]: {
            padding: theme.spacing(5, 5),
        },
        [theme.breakpoints.only('md')]: {
            padding: theme.spacing(5, 25),
            // width: `calc(100% - ${drawerWidth}px)`,
            //marginLeft: drawerWidth

        },
        [theme.breakpoints.only('lg')]: {
            padding: theme.spacing(5, 40),
            //width: `calc(100% - ${drawerWidth}px)`,
            //marginLeft: drawerWidth

        },
        [theme.breakpoints.only('xl')]: {
            padding: theme.spacing(5, 50),
            // width: `calc(100% - ${drawerWidth}px)`,
            //marginLeft: drawerWidth

        },
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(5, 2),

        },
        //marginTop: 40
        width: '100%',
        //  height:'100vh'
        display: 'flex',
        justifyContent: 'center',
        //position: 'absolute'
        // marginBottom: 700
        //flexShrink: 0,

    },
    buttonView: {
        alignSelf: 'center',
        position: 'fixed',
        flexDirection: 'row',
        marginBottom: 0,
        backgroundColor: '#367DB5',
        marginTop: 20,
        height: 70,
        top: 40,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // [theme.breakpoints.only('sm')]: {
        //     width: '100%',

        // },
        // [theme.breakpoints.only('md')]: {
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     // marginLeft: drawerWidth,

        // },
        // [theme.breakpoints.only('lg')]: {
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     // marginLeft: drawerWidth,

        // },
        // [theme.breakpoints.only('xl')]: {
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     //  marginLeft: drawerWidth,

        // },
        // [theme.breakpoints.only('xs')]: {
        //     width: '100%',

        // },
    },
    dupContent: {

        backgroundColor: "#367DB5",

        [theme.breakpoints.only('sm')]: {
            padding: theme.spacing(5, 5),
        },
        [theme.breakpoints.only('md')]: {
            padding: theme.spacing(5, 25),
        },
        [theme.breakpoints.only('lg')]: {
            padding: theme.spacing(5, 40),
        },
        [theme.breakpoints.only('xl')]: {
            padding: theme.spacing(5, 50),
        },
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(5, 2),
        },
        width: '100%',
        height: '100%'
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    button: {
        backgroundColor: 'green',

    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        backgroundColor: 'white'

        // justifyContent: 'flex-end',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#367DB5"
    },
    input: {
        color: 'green',
        backgroundColor: 'red',
        borderColor: 'tomato',
        borderWidth: 2
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

export default function PermanentDrawerLeft(props) {
    //console.log("props  : ", JSON.stringify(props))
    const classes = useStyles();
    const [formData, setFormData] = useState([])
    const [initialFormValues, setInitialFormValues] = useState({})

    const [path, setpath] = useState("")
    const history = useHistory()
    const formRef = useRef()
    const [input, setInput] = useState(true)
    const [output, setOutput] = useState(false)
    const [outputData, setOutputData] = useState({})
    const [width, setWidth] = React.useState(window.innerWidth);
    const [showDrawer, setShowDrawer] = useState(false)
    const breakpoint = 620;
    const [bg, setBg] = useState(0)
    const [report, setReport] = useState(false)
    const [state, setState] = useState(null)

    React.useEffect(() => {
        /* Inside of a "useEffect" hook add an event listener that updates
           the "width" state variable when the window size changes */
        window.addEventListener("resize", () => setWidth(window.innerWidth));

        /* passing an empty array as the dependencies of the effect will cause this
           effect to only run when the component mounts, and not each time it updates.
           We only want the listener to be added once */

    }, []);
    // const { handleSubmit

    // } = useFormikContext();

    const submitTheForm = () => {
        // handleSubmit();
        if (output) {
            return;
        }
        // if (report) {
        //     return;
        // }
        // console.log("formRef.current.values : ", formRef.current.values)
        setReport(false)
        setOutput(true)
        setInput(false)
        if (formRef.current) {
            const keys = Object.keys(formRef.current.values)
            for (let i = 0; i < keys.length; i++) {
                if (!formRef.current.values[keys[i]]) {
                    alert("Please enter all mandatory feilds")
                    return;
                }
            }
            setInitialFormValues(formRef.current.values)

            formRef.current.handleSubmit()

        } else {
            //alert("Hello")
        }
    }

    const inputButton = () => {
        setReport(false)
        setOutput(false)
        setInput(true)

    }

    useEffect(() => {
        // alert(window.location.href)
        window.location.href.includes("aci") && setpath("aci")
        window.location.href.includes("aashto") && setpath("aashto")
        let array = []
        let initialValues = {}
        let index = 0;
        if (window.location.href.includes("25_4_2_3")) {
            array = Deformed_Bars_in_Tension_ACI_25_4_2_3.tension
            index = 0
        } else if (window.location.href.includes("25_4_2_4")) {
            array = Deformed_Bars_in_Tension_ACI_25_4_2_4
            index = 1
        } else if (window.location.href.includes("25_4_3")) {
            array = Deformed_Bars_in_Tension_ACI_25_4_3
            index = 2
        } else if (window.location.href.includes("25_4_4")) {
            array = Deformed_Bars_in_Tension_ACI_25_4_4
            index = 3
        } else if (window.location.href.includes("25_4_9")) {
            array = Deformed_Bars_in_Tension_ACI_25_4_9
            index = 4
        } else if (window.location.href.includes("5_10_8_2_1")) {
            array = Tension_AASHTO_5_10_8_2_1
            index = 0
        } else if (window.location.href.includes("5_10_8_2_4")) {
            array = Hooked_AASHTO_5_10_8_2_4
            index = 1
        } else if (window.location.href.includes("5_10_8_2_2")) {
            array = Compression_AASHTO_5_10_8_2_2
            index = 2
        }
        array.map((item, index) => {
            initialValues = { ...initialValues, [index]: item.answer }
            return undefined
        })
        console.log("initialValues  :  ", initialValues)
        setInitialFormValues(initialValues)
        setFormData(array)
        setOutput(false)
        setInput(true)
        setReport(false)

        setShowDrawer(false)
        setBg(index)
    }, [window.location.href])
    const handleClick = (index) => {
        setBg(index)

        switch (index) {
            case 0:
                path === 'aci' && history.push(`/aci/25_4_2_3`)
                path === 'aashto' && history.push(`/aashto/5_10_8_2_1`)
                break;
            case 1:
                path === 'aci' && history.push(`/aci/25_4_2_4`)
                path === 'aashto' && history.push(`/aashto/5_10_8_2_4`)
                break;
            case 2:
                path === 'aci' && history.push(`/aci/25_4_3`)
                path === 'aashto' && history.push(`/aashto/5_10_8_2_2`)
                break;
            case 3:
                path === 'aci' && history.push(`/aci/25_4_4`)
                break;
            case 4:
                path === 'aci' && history.push(`/aci/25_4_9`)
                break;
        }
    };

    const calculateData = async (data) => {
        let output = []
        if (window.location.href.includes("25_4_2_3")) {
            output = { name: "ACI 25_4_2_3", value: aci25423(data) }
        } else if (window.location.href.includes("25_4_2_4")) {
            output = { name: "ACI 25_4_2_4", value: aci25424(data) }
        } else if (window.location.href.includes("25_4_3")) {
            output = { name: "ACI 25_4_3", value: aci2543(data) }
        } else if (window.location.href.includes("25_4_4")) {
            output = { name: "ACI 25_4_4", value: aci2544(data) }
        } else if (window.location.href.includes("25_4_9")) {
            output = { name: "ACI 25_4_9", value: aci2549(data) }
        } else if (window.location.href.includes("5_10_8_2_1")) {
            output = { name: "AASHTO 5_10_8_2_1", value: aashto510821(data) }
        } else if (window.location.href.includes("5_10_8_2_4")) {
            output = { name: "AASHTO 5_10_8_2_4", value: aashto510824(data) }
        } else if (window.location.href.includes("5_10_8_2_2")) {
            output = {
                name: "AASHTO 5_10_8_2_2", value: aashto510822(data)
            }
        }
        setOutputData(output)
    }
    const numberWithCommas = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    const theme = createMuiTheme({
        palette: {
            primary: green,
        },
    });
    const ValidationTextField = withStyles({
        root: {
            '& input:valid + fieldset': {
                borderColor: 'green',
                borderWidth: 2,
            },
            '& input:invalid + fieldset': {
                borderColor: 'red',
                borderWidth: 2,
            },
            '& input:valid:focus + fieldset': {
                borderLeftWidth: 6,
                padding: '4px !important', // override inline-style
            },
        },
    })(TextField);
    return (
        <div className={classes.root}>
            {/* {console.log("initialFormValues  :  ", JSON.stringify(initialFormValues))} */}
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar
                    style={{ justifyContent: 'center' }}
                >
                    {width < breakpoint && < MenuIcon
                        style={{ color: '#367DB5', marginRight: 10, flex: .1, position: 'absolute', left: 10 }}
                        onClick={() => setShowDrawer(!showDrawer)}
                    />}

                    {width < breakpoint && <img
                        id="logo"
                        onClick={() => {
                            history.go(-1)
                        }
                        }
                        className={classes.img}
                        src="https://i.ibb.co/r7grPXS/logo-with-text.png"
                        alt="image"
                    />}
                    {width < breakpoint && <Typography
                        onClick={() => history.goBack()}
                        style={{ color: 'black', alignSelf: 'center', flex: .2, textAlign: 'center', position: 'absolute', right: 10 }}
                        variant="h6" noWrap>
                        {"Back"}
                    </Typography>}

                </Toolbar>
                {width < breakpoint && <div style={{ backgroundColor: '#F4BC33', height: 5 }}></div>}

            </AppBar>
            <Drawer
                className={showDrawer ? classes.drawer : classes.openDrawer}
                variant={(width > breakpoint || showDrawer) ? "permanent" : 'temporary'}
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                {width > breakpoint &&

                    <img
                        onClick={() => {
                            console.log("props.history.length : ", props.history.length)
                            //  history.go(-1)
                            history.go(-1)

                        }
                        }
                        style={{
                            width: drawerWidth - 70,
                            resizeMode: 'contain',
                            alignSelf: 'center',
                            height: 50,
                            marginTop: 5,
                            marginBottom: 5
                        }}
                        src="https://i.ibb.co/r7grPXS/logo-with-text.png"
                        alt="image"
                    />
                }

                {width > breakpoint &&
                    <Divider
                        style={{ backgroundColor: '#F4BC33', height: 5 }}
                        color="yellow"
                    />
                }

                {/* {width < breakpoint && <div className={classes.drawerHeader}>
                    
                    <Divider
                        style={{ backgroundColor: '#F4BC33', height: 5 }}
                        color="yellow"
                    />
                </div>} */}
                {width < breakpoint &&

                    < CloseIcon
                        style={{ color: '#367DB5', margin: 15 }}
                        onClick={() => setShowDrawer(!showDrawer)}
                    />

                }
                {width < breakpoint && <Divider
                    style={{ backgroundColor: '#F4BC33', height: 5 }}
                    color="yellow"
                />}

                {path && path === "aci" && <div><List style={{ marginTop: -10 }}>
                    {aciLengths && aciLengths.map((item, index) => (
                        <div key={item.name + index}>
                            <ListItem
                                style={{ backgroundColor: bg === index ? '#367DB5' : "white" }}
                                button onClick={() => handleClick(index)}>
                                <ListItemText
                                    style={{ color: bg === index ? 'white' : "#367DB5" }}
                                    primary={item.name} />
                            </ListItem>
                            <Divider />
                        </div>
                    ))}
                </List></div>}

                {path && path === "aashto" && <List style={{ marginTop: -10 }}>
                    {aashtoLengths && aashtoLengths.map((item, index) => (
                        <div key={item.name + index}>
                            {console.log("index:  ", index)}
                            <ListItem
                                style={{ backgroundColor: bg === index ? '#367DB5' : "white" }}
                                button onClick={() => handleClick(index)}>
                                <ListItemText
                                    style={{ color: bg === index ? 'white' : "#367DB5" }}
                                    primary={item.name} />
                            </ListItem>
                            <Divider />

                        </div>
                    ))}
                </List>}
                <Divider />
                <Typography
                    style={{ color: '#F4BC33', textAlign: 'center', position: 'absolute', bottom: 10, alignSelf: 'center' }}
                    variant="h8" noWrap>
                    CRSI Ver 1.0.1
                </Typography>

            </Drawer>
            {/* <div className={classes.dupContent}></div> */}
            <div style={{ backgroundColor: '#367DB5', height: '100vh' }}>
                <div className={classes.content}>
                    <div className={classes.buttonView}>
                        <Button
                            onClick={inputButton}
                            style={{ backgroundColor: input ? "#0072AA" : "#007CB1", color: 'white', paddingHorizontal: 20, paddingRight: 50, paddingLeft: 50, marginTop: 20 }} color="white">Input</Button>
                        <Button
                            type="submit"
                            onClick={submitTheForm}
                            style={{ backgroundColor: output ? "#0072AA" : "#007CB1", color: 'white', paddingHorizontal: 20, paddingRight: 40, paddingLeft: 40, marginTop: 20, marginLeft: 10 }} color="white">Output</Button>

                        {((output && (window.location.href.includes("25_4_2_3") || window.location.href.includes("25_4_2_4"))) || report) && <Button
                            onClick={() => {
                                let id = ''
                                if (window.location.href.includes("25_4_2_3")) {
                                    id = "25_4_2_3"
                                } else if (window.location.href.includes("25_4_2_4")) {
                                    id = "25_4_2_4"
                                } else {
                                    return;
                                }
                                setReport(true)
                                setOutput(false)
                                setInput(false)
                                setState({ ...initialFormValues, id })
                                // history.push({
                                //     pathname: `/aci/${id}/report`,
                                //     state: { ...initialFormValues, id }
                                // })
                            }}
                            style={{ backgroundColor: input ? "#0072AA" : "#007CB1", color: 'white', paddingHorizontal: 20, paddingRight: 50, paddingLeft: 50, marginTop: 20, marginLeft: 10 }} color="white">Generate Report</Button>}

                    </div>
                    {!output && input && <div style={{ marginTop: 110, paddingBottom: 30, paddingLeft: 10, paddingRight: 10, borderRadius: 10, alignSelf: 'center' }}>
                        {input && formData && initialFormValues && <Formik
                            style={{ marginTop: 200 }}
                            innerRef={formRef}
                            enableReinitialize={true}
                            initialValues={initialFormValues}
                            validate={values => {
                                const errors = {};
                                Object.keys(values).forEach(function (key) {
                                    if (values.hasOwnProperty(key)) {
                                        if (formData[key].isMandetory && !values[key])
                                            errors[key] = 'Required';

                                    }
                                });

                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                console.log("values  : ", values)
                                let array = []
                                Object.keys(values).forEach(function (key) {
                                    console.log("values[key]  :  ", values[key])
                                    if (values.hasOwnProperty(key)) {
                                        if (typeof (values[key]) === 'string') {
                                            if (values[key].includes(",")) {
                                                // console.log("includes , ", values[key])
                                                array.push(parseFloat(values[key].replace(/,/g, '')))
                                            } else {
                                                if (isNaN(parseFloat(values[key]))) {
                                                    array.push(values[key].replace(/,/g, ''))
                                                } else {
                                                    array.push(parseFloat(values[key]))
                                                }
                                            }
                                        } else
                                            array.push(values[key])

                                    }
                                });
                                setTimeout(() => {
                                    setSubmitting(false);

                                }, 400);
                                calculateData(array)
                                setOutput(true)
                                setInput(false)

                            }}
                        >
                            {({ values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                isSubmitting, }) => (
                                < Form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        {formData.map((item, index) =>

                                            (item.type === 'radio' && item.visible) ?
                                                <Grid

                                                    key={"" + index}
                                                    item xs={12} sm={12}>
                                                    {getInputLabel(item.label)}
                                                    <div
                                                        style={{ backgroundColor: 'white', marginTop: 10, borderRadius: 5 }}
                                                    >
                                                        {item.options.map((optionItem) => {
                                                            return <div
                                                                onClick={() => setFieldValue("" + index, optionItem.optionLabel)}
                                                                style={{ color: '#367DB5', fontSize: 18, padding: 10, justifyContent: 'center', alignItems: 'center' }}><span>
                                                                    <input type="radio"
                                                                        style={{ width: 15, height: 15 }}
                                                                        id={optionItem.optionLabel}
                                                                        name={"" + index}
                                                                        checked={values["" + index] === optionItem.optionLabel}
                                                                        value={optionItem.optionLabel} />
                                                                </span>  <span style={{ alignSelf: 'center', }}>{optionItem.optionLabel}</span></div>
                                                        })}
                                                    </div>

                                                    {item.note && <FormLabel
                                                        style={{ color: 'black', margin: 10 }}
                                                        color={"white"}
                                                        component="legend">{item.note}
                                                    </FormLabel>}
                                                    {item.conditionalNote && item.conditionalScript && eval(item.conditionalScript) && <FormLabel
                                                        style={{ color: 'black', margin: 10 }}
                                                        color={"white"}
                                                        component="legend">{item.condition1}
                                                    </FormLabel>}
                                                </Grid>

                                                : (item.visible && item.type === 'editText') ? <Grid
                                                    key={"" + index}
                                                    item xs={12} sm={12}>
                                                    {getInputLabel(item.label)}

                                                    <input
                                                        style={{ width: '100%', minHeight: 45, marginTop: 10, borderRadius: 5 }}
                                                        type="text"
                                                        class="float-number"
                                                        placeholder={item.answer}
                                                        name={"" + index}
                                                        onChange={e => {
                                                            // if (e.target.value === "") {
                                                            //     setFieldValue("" + index, item.answer)
                                                            //     return;
                                                            // }
                                                            var nf = new Intl.NumberFormat();
                                                            const removedComma = e.target.value.replaceAll(',', '');
                                                            if (isNaN(Number(removedComma))) {
                                                                return
                                                            }
                                                            if (item.decimal) {
                                                                const array = removedComma.split(".")
                                                                if (array.length > 1) {
                                                                    console.log("(array[1] : ", array[1])
                                                                    if (array[1] && array[1].length > item.decimalValue)
                                                                        return
                                                                }
                                                                setFieldValue("" + index, removedComma)
                                                            } else {
                                                                const val = nf.format(removedComma);
                                                                setFieldValue("" + index, val)
                                                            }

                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values["" + index]}
                                                    />
                                                    {errors["" + index] && <div style={{ color: 'red' }}>{errors["" + index]}</div>}
                                                    {item.note && <FormLabel
                                                        style={{ color: 'black', margin: 10 }}
                                                        color={"white"}
                                                        component="legend">{item.note}
                                                    </FormLabel>}
                                                    {item.conditionalNote && item.conditionalScript && eval(item.conditionalScript) && <FormLabel
                                                        style={{ color: 'black', margin: 10 }}
                                                        color={"white"}
                                                        component="legend">{item.condition1}
                                                    </FormLabel>}
                                                    {item.conditionalNote && item.conditionalScript2 && eval(item.conditionalScript2) && <FormLabel
                                                        style={{ color: 'black', margin: 10 }}
                                                        color={"white"}
                                                        component="legend">{item.condition2}
                                                    </FormLabel>}
                                                </Grid> :
                                                    (item.script && eval(item.script)) && item.type === 'editText' ? <Grid
                                                        key={"" + index}
                                                        item xs={12} sm={12}>
                                                        {getInputLabel(item.label)}
                                                        <input
                                                            style={{ width: '100%', minHeight: 45, marginTop: 10, borderRadius: 5 }}
                                                            type="text"
                                                            placeholder={item.answer}
                                                            class="float-number"
                                                            name={"" + index}
                                                            onChange={e => {
                                                                // if (e.target.value === "") {
                                                                //     setFieldValue("" + index, item.answer)
                                                                //     return;
                                                                // }
                                                                var nf = new Intl.NumberFormat();
                                                                const removedComma = e.target.value.replaceAll(',', '');
                                                                if (isNaN(Number(removedComma))) {
                                                                    return
                                                                }
                                                                if (item.decimal) {
                                                                    const array = removedComma.split(".")
                                                                    if (array.length > 1) {
                                                                        console.log("(array[1] : ", array[1])
                                                                        if (array[1] && array[1].length > item.decimalValue)
                                                                            return
                                                                    }
                                                                    setFieldValue("" + index, removedComma)
                                                                } else {
                                                                    const val = nf.format(removedComma);
                                                                    setFieldValue("" + index, val)
                                                                }

                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values["" + index]}
                                                        />

                                                        {errors["" + index] && <div style={{ color: 'red' }}>{errors["" + index]}</div>}
                                                        {item.note && <FormLabel
                                                            style={{ color: 'black', margin: 10 }}
                                                            color={"white"}
                                                            component="legend">{item.note}
                                                        </FormLabel>}
                                                    </Grid> : (item.script && eval(item.script)) && item.type === 'radio' ? <Grid
                                                        key={"" + index}
                                                        item xs={12} sm={12}>
                                                        {getInputLabel(item.label)}
                                                        <div
                                                            style={{ backgroundColor: 'white', marginTop: 10, borderRadius: 5 }}
                                                        >
                                                            {item.options.map((optionItem) => {
                                                                return <div
                                                                    onClick={() => setFieldValue("" + index, optionItem.optionLabel)}
                                                                    style={{ color: '#367DB5', fontSize: 18, padding: 10, justifyContent: 'center' }}><span>
                                                                        <input type="radio"
                                                                            style={{ width: 15, height: 15 }}
                                                                            id={optionItem.optionLabel}
                                                                            name={"" + index}
                                                                            checked={values["" + index] === optionItem.optionLabel}
                                                                            value={optionItem.optionLabel} />
                                                                    </span>  <span style={{ alignSelf: 'center' }}>{optionItem.optionLabel}</span></div>
                                                            })}
                                                        </div>


                                                        {item.note && <FormLabel
                                                            style={{ color: 'black', margin: 10 }}
                                                            color={"white"}
                                                            component="legend">{item.note}
                                                        </FormLabel>}
                                                    </Grid> : null
                                        )}

                                    </Grid>
                                </Form>
                            )}
                        </Formik>}
                    </div>}
                    {output && <Result
                        location={{ state: outputData }}
                    />}
                    {report && <ReportComponent
                        state={state}
                        output={outputData}
                    />}
                </div>
            </div>

        </div >
    );
}