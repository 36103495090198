import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ls from 'local-storage'
import firebase from "firebase/app";

const useStyles = makeStyles((theme) => ({

}));

const PaymentStatus = (props) => {

    useEffect(() => {
        const module = ls.get('module');


        if (window.location.href.includes("Result=APPROVED")) {
            alert("Transaction Approved")
            let userData = ls('user');
            const user = JSON.parse(userData)
            var userId = user.uid;
            if (module === 'aci') {
                writeUserData(userId, "subscriptionID318", makeid(10))
            }
            if (module === 'aashto') {
                writeUserData(userId, "subscriptionIDaastho", makeid(10))
            }
            setTimeout(() => {
                window.history.replaceState(null, null, "/");
            }, 3000)
        } else if (window.location.href.includes("Result=DECLINED")) {
            alert("Transaction Declined")

        } else {
            alert("Some Unexpected error occurred")
        }
    }, [])

    const writeUserData = (userId, moduleName, value) => {
        firebase.database().ref('users/' + userId).set({
            moduleName: value,
        });
    }


    const makeid = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    return (
        <div>Please Wait while processing your payment</div>
    );
}

export default PaymentStatus
