import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { authLoginSucess } from '../actions/auth'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppBar, Button, Card, Toolbar } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { aci25423, aci25424, generateReportAci25423, generateReportAci25424 } from '../formulas/formulas';
import { jsPDF } from "jspdf";
const doc = new jsPDF();


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 100,
        //maxWidth: '80%'
    },
    holder: {
        width: '100%',
        height: "100dv",
        backgroundColor: '#367DB5',
    },
    root: {
        justifyContent: 'center',
    },
    div: {
        justifyContent: 'space-around',
        // backgroundColor: 'red',
        alignItems: 'center',
        marginTop: 15,
        flexDirection: 'row'
    },
    divBottom: {
        justifyContent: 'space-around',
        // backgroundColor: 'red',
        alignItems: 'center',
        marginTop: 5,
        flexDirection: 'row'
    },
    card: {
        flexDirection: 'column',
        width: '100%',
        padding: 15,
        alignSelf: 'center',
        borderWidth: 5

    },
    div1: {
        //  display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 5,
        fontSize: 17,
        justifyContent: 'center',
        alignItems: 'center'

    },
    div2: {
        display: 'flex',
        width: '100%',
        color: '#FFFFFF'

    },
    name: {
        // flex: .9,
        color: '#FFFFFF'

    },
    value: {
        // flex: .1
        marginLeft: 20,
        color: '#FFFFFF'
    },
    nameHeader: {
        color: '#367DB5'
    },
    valueHeader: {
        marginLeft: 20,
        color: '#367DB5'
    },
    topAppBar: {
        top: 0,
        bottom: 'auto',
        backgroundColor: 'white'
    },
    title: {
        flexGrow: 1,
        color: '#7E7E7E',
        width: '100%',

    },
    container: {
        backgroundColor: '#367DB5',
        padding: theme.spacing(12)
    }, img: {
        width: 200,
        resizeMode: 'contain',
        alignSelf: 'center'

    }
}));

const ReportScreen = (props) => {
    const { authLoginSucess } = props
    const classes = useStyles();
    const { state } = props.location
    const [middle, setmiddle] = useState(false)
    const [rows, setRows] = useState(null)
    // const [rows, setRows] = useState(null)

    // const { state } = useLocation();
    // const { id, color } = state;
    useEffect(() => {
        //alert(JSON.stringify(state))
        calculateData(state)
    }, [])

    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }


    const calculateData = async (data) => {
        let output = []
        let array = []
        let object = data;
        let id = object.id;
        delete object.id
        Object.keys(object).forEach(function (key) {
            console.log("values[key]  :  ", object[key])
            if (object.hasOwnProperty(key)) {
                if (typeof (object[key]) === 'string') {
                    if (object[key].includes(",")) {
                        // console.log("includes , ", values[key])
                        array.push(parseFloat(object[key].replace(/,/g, '')))
                    } else {
                        if (isNaN(parseFloat(object[key]))) {
                            array.push(object[key].replace(/,/g, ''))
                        } else {
                            array.push(parseFloat(object[key]))
                        }
                    }
                } else
                    array.push(object[key])
            }
        });
        if (id === "25_4_2_3") {
            let length = ["#3", "#4", "#5", "#6", "#7", "#8", "#9", "#10", "#11", "#14", "#18"]
            const report = generateReportAci25423(array)
            const dataForRows = report && report.map((item, index) => {
                //console.log("item : ", item)
                return createData(length[index], item[6]?.value, item[7]?.value, item[8]?.value)
            })
            setRows(dataForRows);
        } else if (id === "25_4_2_4") {
            let length = ["#3", "#4", "#5", "#6", "#7", "#8", "#9", "#10", "#11", "#14", "#18"]

            const report = generateReportAci25424(array)
            console.log("report :  ", JSON.stringify(report))
            const dataForRows = report && report.map((item, index) => {
                //console.log("item : ", item)
                return createData(length[index], item[10]?.value, item[11]?.value, item[12]?.value)
            })
            setRows(dataForRows);
        }
        //setOutputData(output)
    }

    const generatePdf = () => {
        doc.setFontSize(14);

        const headerStyle = 'style="padding: 15px;font-size: 18px;text-align: center;';
        doc.text(window.location.href.includes("25_4_2_3") ? "ACI 25.4.2.3" : "ACI 25.4.2.4", 10, 15);
        doc.text("Concrete: Normalweight f'c = " + props.location.state["2"] + " psi", 10, 25);
        doc.text("Reinforcing steel f'y = "+props.location.state["1"], 10, 35);
        doc.setFontSize(10);
        // doc.maxWidth(50)
        doc.text("Bar Size", 10, 60, { maxWidth: 25 });
        doc.text("Tension Development Length (in.), la", 30, 60, { maxWidth: 50 });
        doc.text("Class A Tension Lap Splice Length (in.)", 85, 60, { maxWidth: 50 });
        doc.text("Class B Tension Lap Splice Length (in.)", 140, 60, { maxWidth: 50 });
        doc.line(10, 68, 190, 68)

        doc.setFontSize(12);

        for (let i = 0; i < rows.length; i++) {
            doc.text(" " + rows[i].name + "" || "", 10, 75 + i * 8, { maxWidth: 25, align: 'left', renderingMode: "fill" });
            doc.text("   " + rows[i].calories + "" || "", 30, 75 + i * 8, { maxWidth: 50, align: 'left' });
            doc.text("   " + rows[i].fat + "" || "", 85, 75 + i * 8, { maxWidth: 50, align: 'left' });
            doc.text("   " + rows[i].carbs + "" || "", 140, 75 + i * 8, { maxWidth: 50, align: 'left' });
            doc.line(10, 75 + i * 8 + 2, 190, 75 + i * 8 + 2)
            console.log("row : ", rows[i])
        }

        doc.save("a4.pdf");
    }
    const format = (format, value) => {
        if (format) {
            console.log("inside format", "" + Number(value).toFixed(format))
            return "" + Number(value).toFixed(format)
        } else {
            console.log("inside non format", "" + Number(value).toFixed(format))
            console.log("inside non format value", "" + value)
            if (isNaN(value)) {
                console.log("inside nan", "" + value)
                return "" + "0"
            }
            else {
                console.log("inside not nan", "" + value)
                return value
            }
        }
    }
    return (
        <div style={{
            display: 'flex',
            height: 1000,
            justifyContent: 'center',
            backgroundColor: "#367DB5",

        }}>
            <AppBar position="fixed" color="primary" className={classes.topAppBar}>
                <Toolbar>

                    <div style={{ flex: .93, display: 'flex', justifyContent: 'center' }}>
                        <img
                            className={classes.img}
                            src="https://i.ibb.co/r7grPXS/logo-with-text.png"
                            alt="image"
                        />
                    </div>
                    <div style={{ flex: .07, justifyContent: "flex-end", alignItems: 'flex-end' }}>

                    </div>

                </Toolbar>
                <div style={{ backgroundColor: '#F4BC33', height: 5 }}></div>
            </AppBar>
            <div
                style={{
                    paddingRight: '20%', paddingLeft: '20%', paddingTop: 70
                }}
            >
                <h2 style={{ color: 'white' }}>{window.location.href.includes("25_4_2_3") ? "ACI 25.4.2.3" : "ACI 25.4.2.4"}</h2>
                <h3 style={{ color: 'white' }}>Concrete: Normalweight <i>f</i>'c = {props.location.state["2"]} psi</h3>
                <h3 style={{ color: 'white' }}>Reinforcing steel <i>f</i>'y = {props.location.state["1"]}</h3>

                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Bar Size</TableCell>
                                <TableCell align="right">Tension Development Length (in.), la</TableCell>
                                <TableCell align="right">Class A Tension Lap Splice Length (in.)</TableCell>
                                <TableCell align="right">Class B Tension Lap Splice Length (in.)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows && rows.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.calories}</TableCell>
                                    <TableCell align="right">{row.fat}</TableCell>
                                    <TableCell align="right">{row.carbs}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    onClick={generatePdf}
                    style={{ backgroundColor: "#0072AA", color: 'white', paddingHorizontal: 20, paddingRight: 50, paddingLeft: 50, marginTop: 20 }} color="white">Export to PDF</Button>
            </div>
        </div>


    );
}
const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    // isLoading: state.global.isLoading
});


const mapDispatchToProps = dispatch => ({
    dispatch,
    ...bindActionCreators(
        {
            //  doLogout: doLogoutAPI,
            authLoginSucess
        }, dispatch),
});
const Report = connect(mapStateToProps, mapDispatchToProps)(ReportScreen);
export default Report;
