import {
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGOUT,
} from '../actions/auth';

const initialState = {
    isLoggedIn: false,
    isError: false,
    message: '',
};

export const auth = (state = initialState, action) => {
    switch (action.type) {

        case AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
            }


        case AUTH_LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
            }

        default:
            return { ...state };
    }
};