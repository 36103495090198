import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//import { withSpinner } from './withSpinner';
//import ValidateIdleTime from './ValidateIdleTime';
import { authLoginSucess } from "../actions/auth";
import { UnAuthenticatedUserPage } from '../routes/UnAuthenticatedUserPage';
import { AuthenticatedUserPage } from '../routes/AuthenticatedUserPage';

import { bindActionCreators } from 'redux';
//var ls = require('local-storage');
import ls from 'local-storage'

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    // isLoading: state.global.isLoading
});


const mapDispatchToProps = dispatch => ({
    dispatch,
    ...bindActionCreators(
        {
            //  doLogout: doLogoutAPI,
            authLoginSucess
        }, dispatch),
});

const MainPageBase = ({ isLoggedIn, authLoginSucess }) => {


    useEffect(() => {
        // ls.get('user').then((data) => {
        //     const user = JSON.parse("data")
        //     if (user) {
        //         console.log("user : ", JSON.stringify(user))
        //         authLoginSucess()
        //     }
        // })
        if(ls.get('user')){
            authLoginSucess()
        }
    }, [])

    return (
        <div style={{ height: '100%' }}>
            {isLoggedIn ? <AuthenticatedUserPage /> : <UnAuthenticatedUserPage />}

        </div>
    );
}


//const MainPageWithSpinner = withSpinner(MainPageBase);

export const MainPage = connect(mapStateToProps, mapDispatchToProps)(MainPageBase);
