export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const USER_UNAUTHENTICATED = "USER_UNAUTHENTICATED";
export const AUTH_LOGIN_FAILURE_CLOSE = "AUTH_LOGIN_FAILURE_CLOSE";
export const AUTH_LOGIN_ERROR_CLOSE = "AUTH_LOGIN_ERROR_CLOSE";
export const AUTH_LOGIN_USER_UNAUTHENTICATED_CLOSE = "AUTH_LOGIN_USER_UNAUTHENTICATED_CLOSE";

export const authLoginSucess = () => ({
    type: AUTH_LOGIN_SUCCESS,   
});

export const authLogout = () => ({
    type: AUTH_LOGOUT,   
});